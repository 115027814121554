import { format, isBefore } from "date-fns";
import React, { useEffect, useState } from "react";

import type {
  BookingFragment,
  CanMerchantInitiatePrepaidBooking,
  RequestBookingImprintForBookingInput,
  RequestBookingOtpForBookingInput,
} from "../../../graphql/generated/schema";
import {
  Booking_Status,
  Origin,
  Payment_Card_Status,
  useCanMerchantInitiatePrepaidBookingLazyQuery,
} from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import ButtonActionsListModal from "../../BottomButtomWithActions/ButtonActionsListModal";
import type { BUTTON_ACTION } from "../../BottomButtomWithActions/index";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal";
import CustomCalendar, { CALENDAR_VIEW } from "../../CustomCalendar";

interface BookingActionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (key: string) => void;
  handleCancel?: () => void;
  onBookingImprintRequestConfirm: (request: RequestBookingImprintForBookingInput) => void;
  onOtpRequestConfirm: (request: RequestBookingOtpForBookingInput) => void;
  onDuplicateConfirm: (dates: Date[]) => void;
  booking: BookingFragment;
}

enum BOOKING_ACTIONS {
  DUPLICATE = "DUPLICATE",
  BOOKING_IMPRINT = "BOOKING_IMPRINT",
  OTP = "OTP",
  DELETE = "DELETE",
  MODIFY = "MODIFY",
}

const BookingActionsModal = ({
  isOpen,
  onClose,
  handleSubmit,
  handleCancel,
  onBookingImprintRequestConfirm,
  onOtpRequestConfirm,
  onDuplicateConfirm,
  booking,
}: BookingActionsModalProps) => {
  const {
    merchantClient,
    status,
    source,
    prepaidPayment,
    payment,
    otp,
    otpRequest,
    prepaidRequest,
  } = booking;
  const [action, setAction] = useState<BOOKING_ACTIONS | undefined>(undefined);
  const [dates, setDates] = React.useState<Date[]>([]);
  const [merchantCapabilities, setMerchantCapabilities] =
    useState<CanMerchantInitiatePrepaidBooking | null>(null);

  const [canMerchantInitiatePrepaidBooking] =
    useCanMerchantInitiatePrepaidBookingLazyQuery();

  const handleGetMerchantCapabilities = async () => {
    try {
      const response = await canMerchantInitiatePrepaidBooking();

      if (response.data?.canMerchantInitiatePrepaidBooking) {
        setMerchantCapabilities(response.data.canMerchantInitiatePrepaidBooking);
      }
    } catch (err) {}
  };

  useEffect(() => {
    handleGetMerchantCapabilities();
  }, []);

  const getButtonActions = () => {
    if (status === Booking_Status.Refunded) return [];
    if (!merchantClient) return [];

    const IS_BEFORE_CURRENT_DATE = isBefore(new Date(booking?.date), new Date());

    const BUTTON_ACTIONS: BUTTON_ACTION[] = [];

    const CAN_DUPLICATE = source !== Origin.Google;

    if (handleCancel) {
      BUTTON_ACTIONS.push({
        title: "ANNULER",
        key: "CANCEL",
        variant: "primary",
        color: "danger",
      });
    }

    if (CAN_DUPLICATE) {
      BUTTON_ACTIONS.unshift({
        title: "DUPLIQUER LA RÉSERVATION",
        key: "DUPLICATE",
        variant: "outline",
        color: "success",
      });
    }

    const CAN_DELETE =
      status === Booking_Status.CancelledByOwner ||
      status === Booking_Status.CancelledByUser;

    if (CAN_DELETE) {
      BUTTON_ACTIONS.unshift({
        title: "SUPPRIMER LA RÉSERVATION",
        key: "DELETE",
        variant: "outline",
        color: "danger",
      });
    }

    const CAN_ASK_FOR_OTP =
      status !== Booking_Status.Ended &&
      status !== Booking_Status.CancelledByOwner &&
      status !== Booking_Status.CancelledByUser &&
      status !== Booking_Status.ModifiedByOwner &&
      status !== Booking_Status.ModifiedByUser &&
      status !== Booking_Status.Seated &&
      !prepaidPayment?.paid &&
      !payment?.paid &&
      !otp?.verified &&
      !prepaidRequest;

    const otpDisabled = !merchantCapabilities?.isMerchantAbleToSendSms
      ? {
          message:
            "Veuillez recharger votre compte pour envoyer une vérification téléphonique.",
        }
      : undefined;

    if (CAN_ASK_FOR_OTP && !IS_BEFORE_CURRENT_DATE) {
      const title = !!otpRequest
        ? "RELANCER LA VÉRIFICATION TÉLÉPHONIQUE"
        : "DEMANDER UNE VÉRIFICATION TÉLÉPHONIQUE";

      BUTTON_ACTIONS.unshift({
        title,
        key: "OTP_REQUEST",
        variant: "outline",
        color: "orange",
        disabled: otpDisabled,
      });
    }

    const CAN_ASK_FOR_PREPAID =
      status !== Booking_Status.Ended &&
      status !== Booking_Status.CancelledByOwner &&
      status !== Booking_Status.CancelledByUser &&
      status !== Booking_Status.ModifiedByOwner &&
      status !== Booking_Status.ModifiedByUser &&
      status !== Booking_Status.Seated &&
      !prepaidPayment?.paid &&
      !payment?.paid &&
      !otpRequest;

    const prepaidDisabled = !merchantCapabilities?.isMerchantAbleToAcceptPayments
      ? {
          message:
            " Veuillez connecter votre compte stripe et activer les paiements en ligne pour pouvoir utiliser cette fonctionnalité.",
        }
      : undefined;

    if (CAN_ASK_FOR_PREPAID && !IS_BEFORE_CURRENT_DATE) {
      const title = !!prepaidRequest
        ? "RELANCER L'EMPREINTE BANCAIRE"
        : "DEMANDER UNE EMPREINTE BANCAIRE";

      BUTTON_ACTIONS.unshift({
        title,
        key: "PREPAID_REQUEST",
        variant: "outline",
        color: "orange",
        disabled: prepaidDisabled,
      });
    }

    const CAN_CANCEL_PREPAID_REQUEST = prepaidRequest && !prepaidPayment?.paid;

    if (CAN_CANCEL_PREPAID_REQUEST) {
      BUTTON_ACTIONS.unshift({
        title: "ANNULER LA DEMANDE D'EMPREINTE BANCAIRE",
        key: "CANCEL_PREPAID_REQUEST",
        variant: "outline",
        color: "danger",
      });
    }

    const CAN_CANCEL_PREPAID =
      prepaidPayment?.paid &&
      prepaidPayment?.cardPaymentStatus === Payment_Card_Status.NotCharged;

    if (CAN_CANCEL_PREPAID) {
      BUTTON_ACTIONS.unshift({
        title: "ANNULER/LIBÉRER L'EMPREINTE BANCAIRE",
        key: "CANCEL_PREPAID",
        variant: "outline",
        color: "danger",
      });
    }

    const CAN_REFUND_PREPAID =
      prepaidPayment?.paid &&
      prepaidPayment?.cardPaymentStatus === Payment_Card_Status.Charged;

    if (CAN_REFUND_PREPAID) {
      BUTTON_ACTIONS.unshift({
        title: "REMBOURSER L'EMPREINTE BANCAIRE",
        key: "REFUND_PREPAID",
        variant: "outline",
        color: "purple",
      });
    }

    return BUTTON_ACTIONS;
  };

  const handleClose = () => {
    setDates([]);
    onClose();
    setAction(undefined);
  };

  const handleOnSubmit = async (key: string) => {
    try {
      if (key === "DELETE") {
        handleSubmit(key);
        handleClose();
      } else if (key === "PREPAID_REQUEST") {
        setAction(BOOKING_ACTIONS.BOOKING_IMPRINT);
        // handleSubmit(key);
        // handleClose();
      } else if (key === "OTP_REQUEST") {
        setAction(BOOKING_ACTIONS.OTP);
        // handleSubmit(key);
        // handleClose();
      } else if (key === "DUPLICATE") {
        setAction(BOOKING_ACTIONS.DUPLICATE);
      } else if (key === "MODIFY") {
        handleSubmit(key);
        handleClose();
      } else if (key === "CANCEL") {
        handleCancel?.();
        handleClose();
      } else if (key === "CANCEL_PREPAID") {
        handleSubmit(key);
        handleClose();
      } else if (key === "REFUND_PREPAID") {
        handleSubmit(key);
        handleClose();
      } else if (key === "CANCEL_PREPAID_REQUEST") {
        handleSubmit(key);
        handleClose();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleConfirm = () => {
    onDuplicateConfirm(dates);
    handleClose();
  };

  const onDateSelected = (date: Date) => {
    const existingDate = dates.find(d => d.getTime() === date.getTime());

    if (existingDate) {
      setDates(dates.filter(d => d.getTime() !== date.getTime()));
    } else {
      setDates([...dates, date]);
    }
  };

  const getMarkedDates = () => {
    const markedDates: Record<string, unknown> = {};

    dates.forEach(date => {
      markedDates[format(date, "yyyy-MM-dd")] = {
        selected: true,
        marked: true,
        selectedColor: PALETTE.green,
      };
    });

    markedDates[format(new Date(booking.date), "yyyy-MM-dd")] = {
      disabled: true,
      disableTouchEvent: true,
    };

    return markedDates;
  };

  const handleBookingImprintRequestConfirmation = (
    param: RequestBookingImprintForBookingInput,
  ) => {
    onBookingImprintRequestConfirm(param);
    handleClose();
  };

  const handleBookingOtpRequestConfirmation = (
    param: RequestBookingOtpForBookingInput,
  ) => {
    onOtpRequestConfirm(param);
    handleClose();
  };

  const displayDuplicateCalendarModal = () => {
    return (
      <>
        <CustomCalendar
          onDateChange={onDateSelected}
          calendarView={CALENDAR_VIEW.MONTH}
          minDate={new Date()}
          markedDates={getMarkedDates()}
          markingType="simple"
        />

        <Box mt="m">
          <CustomButton
            disabled={dates.length === 0}
            buttonVariant="primaryButton"
            buttonColor={dates.length === 0 ? "lightGrey" : "success"}
            onPress={handleConfirm}
          >
            <CustomText variant="primaryButtonText" color="white">
              Valider
            </CustomText>
          </CustomButton>
        </Box>
      </>
    );
  };

  const displayImprintModal = () => {
    const canSendImprintBySms = !!merchantCapabilities?.isMerchantAbleToSendSms;

    return (
      <Box>
        <Box mb="s">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="orange"
            buttonColor={"transparent"}
            onPress={() =>
              handleBookingImprintRequestConfirmation({
                bookingId: booking._id,
                shouldGenerateLinkImmediately: true,
                shouldSendByMail: false,
                shouldSendBySms: false,
              })
            }
          >
            <CustomText variant="outlineButtonText" color="orange">
              Générer un lien empreinte bancaire immédiatement
            </CustomText>
          </CustomButton>
        </Box>
        <Box mb="s">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="orange"
            buttonColor={"transparent"}
            onPress={() =>
              handleBookingImprintRequestConfirmation({
                bookingId: booking._id,
                shouldGenerateLinkImmediately: false,
                shouldSendByMail: true,
                shouldSendBySms: false,
              })
            }
          >
            <CustomText variant="outlineButtonText" color="orange">
              Demande d'empreinte bancaire par email
            </CustomText>
          </CustomButton>
        </Box>
        <Box mb="s">
          <CustomButton
            disabled={!canSendImprintBySms}
            buttonVariant="outlineButton"
            borderColor={!canSendImprintBySms ? "lightGrey" : "orange"}
            buttonColor={"transparent"}
            onPress={() =>
              handleBookingImprintRequestConfirmation({
                bookingId: booking._id,
                shouldGenerateLinkImmediately: false,
                shouldSendByMail: false,
                shouldSendBySms: true,
              })
            }
          >
            <CustomText
              variant="outlineButtonText"
              color={!canSendImprintBySms ? "lightGrey" : "orange"}
            >
              Demande d'empreinte bancaire par sms
            </CustomText>
          </CustomButton>

          {!canSendImprintBySms && (
            <CustomText variant="text" color="lightGrey" mt="xs">
              Veuillez recharger vos crédits SMS pour envoyer une demande par SMS.
            </CustomText>
          )}
        </Box>
      </Box>
    );
  };

  const displayOTPModal = () => {
    return (
      <Box>
        <Box mb="s">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="orange"
            buttonColor={"transparent"}
            onPress={() =>
              handleBookingOtpRequestConfirmation({
                bookingId: booking._id,
                shouldGenerateLinkImmediately: false,
                shouldSendByMail: true,
                shouldSendBySms: false,
              })
            }
          >
            <CustomText variant="outlineButtonText" color="orange">
              Demande de vérification téléphonique par email
            </CustomText>
          </CustomButton>
        </Box>
        <Box mb="s">
          <CustomButton
            buttonVariant="outlineButton"
            borderColor="orange"
            buttonColor={"transparent"}
            onPress={() =>
              handleBookingOtpRequestConfirmation({
                bookingId: booking._id,
                shouldGenerateLinkImmediately: false,
                shouldSendByMail: false,
                shouldSendBySms: true,
              })
            }
          >
            <CustomText variant="outlineButtonText" color="orange">
              Demande de vérification téléphonique par sms
            </CustomText>
          </CustomButton>
        </Box>
      </Box>
    );
  };

  const dislayContent = () => {
    const BUTTON_ACTIONS: BUTTON_ACTION[] = getButtonActions();

    if (action === BOOKING_ACTIONS.DUPLICATE) {
      return displayDuplicateCalendarModal();
    }

    if (action === BOOKING_ACTIONS.BOOKING_IMPRINT) {
      return displayImprintModal();
    }

    if (action === BOOKING_ACTIONS.OTP) {
      return displayOTPModal();
    }

    return <ButtonActionsListModal actions={BUTTON_ACTIONS} onPress={handleOnSubmit} />;
  };

  const getModalTitle = () => {
    if (action === BOOKING_ACTIONS.DUPLICATE) {
      return "Dupliquer la réservation";
    }
    if (action === BOOKING_ACTIONS.BOOKING_IMPRINT) {
      return "Demander une empreinte bancaire";
    }
    if (action === BOOKING_ACTIONS.OTP) {
      return "Vérification téléphonique";
    }
    return "Actions";
  };

  return (
    <ContentModal
      title={getModalTitle()}
      isVisible={isOpen}
      onClose={handleClose}
      hasBackButton={
        action === BOOKING_ACTIONS.BOOKING_IMPRINT || action === BOOKING_ACTIONS.OTP
      }
      onGoBack={() => setAction(undefined)}
      styles={{
        maxWidth: 450,
        height: "auto",
      }}
    >
      <>{isOpen && dislayContent()}</>
    </ContentModal>
  );
};

export default BookingActionsModal;
