import { Pressable, PressableProps } from "react-native";

// const IS_WEB = Platform.OS === "web";
// const IS_IOS = Platform.OS === "ios";

const Touchable = (props: PressableProps) => {
  return (
    <Pressable
      {...props}
      style={({ pressed }) => {
        const style =
          typeof props.style === "function" ? props.style({ pressed }) : props.style;
        return [
          style,
          {
            opacity: pressed ? 0.5 : 1,
          },
        ];
      }}
    />
  );

  // if (IS_IOS || IS_WEB) {
  //   return <TouchableOpacity {...props} />;
  // }

  // return <RNTouchableOpacity {...props} />;
};

export default Touchable;
