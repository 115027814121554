import { FlatList } from "react-native";

import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import { CustomButton } from "../Button";

import type { BUTTON_ACTION } from ".";

interface ButtonActionsListModalProps {
  actions: BUTTON_ACTION[];
  onPress: (key: string) => void;
}

const ButtonActionsListModal = ({ actions, onPress }: ButtonActionsListModalProps) => {
  return (
    <FlatList
      scrollEnabled={false}
      data={actions}
      ListEmptyComponent={
        <Box alignItems="center" justifyContent="center">
          <CustomText variant="content" color="primaryTextColor">
            Aucune action possible
          </CustomText>
        </Box>
      }
      renderItem={({ item }) => {
        const isDisabled = !!item.disabled;

        const borderColor = isDisabled ? "lightGrey" : item.color;

        const buttonColor = isDisabled
          ? "transparent"
          : item.variant === "primary"
          ? item.color
          : "transparent";

        const textColor = isDisabled
          ? "lightGrey"
          : item.variant === "primary"
          ? "white"
          : item.color;

        return (
          <Box mb="s">
            <CustomButton
              disabled={isDisabled}
              buttonVariant={
                item.variant === "primary" ? "primaryButton" : "outlineButton"
              }
              borderColor={borderColor}
              buttonColor={buttonColor}
              onPress={() => onPress(item.key)}
            >
              <CustomText variant="outlineButtonText" color={textColor}>
                {item.title}
              </CustomText>
            </CustomButton>
            {item?.disabled?.message && (
              <CustomText variant="text" color="lightGrey" mt="xs">
                {item.disabled.message}
              </CustomText>
            )}
          </Box>
        );
      }}
    />
  );
};

export default ButtonActionsListModal;
