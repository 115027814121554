import { useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import type { ImagePickerAsset } from "expo-image-picker";
import { useContext, useEffect, useMemo, useState } from "react";
import { FlatList, Image, ScrollView, TouchableOpacity } from "react-native";
import DraggableFlatList from "react-native-draggable-flatlist";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import PLUS from "../../../../../../../assets/icons/BASE/PLUS.svg";
import TRASH from "../../../../../../../assets/icons/BASE/TRASH.svg";
import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import { CustomButton } from "../../../../../../components/Button";
import ButtonList from "../../../../../../components/ButtonList";
import DateVisibilitySection from "../../../../../../components/DateVisibilitySection";
import InlineSelectButton from "../../../../../../components/InlineSelectButton";
import KeyboardAwareScrollView from "../../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../../components/Loader";
import AddNewImageModal from "../../../../../../components/Media/AddNewImageMedia";
import MenuStepCard from "../../../../../../components/MenuStep";
import MenuStepPriceCard from "../../../../../../components/MenuStepPriceCard";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import SelectButton from "../../../../../../components/Select/SelectButton";
import ColorListSelect from "../../../../../../components/Settings/ColorListSelect";
import { CustomTextInput } from "../../../../../../components/TextInput";
import Toggle from "../../../../../../components/Toggle";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  CreateMenu,
  MenuFragment,
  MenuPrice,
  MenuStepFragment,
  MenuStepInput,
  PriceAmount,
  PricingRateFragment,
} from "../../../../../../graphql/generated/schema";
import {
  GetMenusDocument,
  useCreateMenuMutation,
  useDeleteMenuMutation,
  useGetMenuLazyQuery,
  useGetPricingRatesLazyQuery,
  useSavePictureMutation,
  useUpdateMenuMutation,
} from "../../../../../../graphql/generated/schema";
import { PALETTE } from "../../../../../../theme/Palette";
import type { ERROR } from "../../../../../../utils/common";
import { formaYupErrors, removeTypeNames } from "../../../../../../utils/common";

interface MenuListDetailsEditProps {
  id?: string;
  goBack?: () => void;
}

const IMAGE_SIZE = 95;

const DEFAULT_MENU: CreateMenu = {
  baseAvailability: {
    isRecurring: false,
    recurringDates: {
      days: [],
      endTime: "10:00",
      startTime: "10:00",
    },
    specificDates: {
      endDate: new Date().toISOString(),
      endTime: "10:00",
      startDate: new Date().toISOString(),
      startTime: "10:00",
    },
  },
  color: "",
  description: "",
  draftMenuSteps: [],
  isEnabled: true,
  isPhysicalItem: true,
  name: "",
  picture: "",
  position: 0,
  prices: [],
  productCode: "",
  savedMenuSteps: [],
  slug: "",
  tags: [],
  pricingRatePrices: [],
};

const schema = yup.object().shape({
  name: yup.string().required("Le nom est requis"),
  color: yup.string().required("La couleur est requise"),
  slug: yup.string().required("La réference du produit est requis"),
  productCode: yup.string().required("Le code produit est requis"),
  description: yup.string(),
  isEnabled: yup.boolean().required(),
  savedMenuSteps: yup
    .array()
    .of(
      yup.object().shape({
        _id: yup.string().required(),
      }),
    )
    .min(1, "Vous devez ajouter au moins une étape"),
  prices: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          menuStepIds: yup.array().of(yup.string()).required(),
          basePrice: yup
            .object()
            .shape({
              amount: yup
                .number()
                .typeError("Le prix d'une étape doit être supérieur à 0")
                .min(1, "Le prix d'une étape doit être supérieur à 0"),
              isEnabled: yup.boolean().required(),
            })
            .required(),
        })
        .required(),
    )
    .min(1, "Vous devez ajouter au moins un prix"),
});

const MenuListDetailsEdit = ({ id, goBack }: MenuListDetailsEditProps) => {
  useResizeMode();
  const navigation = useNavigation();
  const isNew = !id;
  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(DEFAULT_MENU);
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [pricingRates, setPricingRates] = useState<PricingRateFragment[]>([]);
  const [isImagePickerOpen, setIsImagePickerOpen] = useState(false);

  const [getPricingRates] = useGetPricingRatesLazyQuery();
  const [getMenu] = useGetMenuLazyQuery();
  const [createMenu] = useCreateMenuMutation();
  const [updateMenu] = useUpdateMenuMutation();
  const [deleteMenu] = useDeleteMenuMutation();
  const [savePicture] = useSavePictureMutation();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const handleGenerateMenuPrices = () => {
    const DEFAULT_PRICE_AMOUNT: PriceAmount = {
      amount: 0,
      isEnabled: true,
    };
    const stepsEnabled = menu.savedMenuSteps.filter(s => s.isEnabled);

    const required = stepsEnabled.filter(s => s.selectionSettings.minSelection >= 1);
    const optionnalSteps = stepsEnabled.filter(
      s => s.selectionSettings.minSelection === 0,
    );

    const requiredStepsIds = required.map(s => s._id);

    const requiredStepsPrice = {
      menuStepIds: requiredStepsIds,
      basePrice: DEFAULT_PRICE_AMOUNT,
    };

    const spreadOptionnalStepsIds = optionnalSteps.map((_, i) =>
      optionnalSteps.slice(0, i + 1).map(s => s._id),
    );

    const requiredOptionnalSteps = optionnalSteps.map(s => {
      const stepIds = requiredStepsIds.concat(s._id);
      return {
        menuStepIds: stepIds,
        basePrice: DEFAULT_PRICE_AMOUNT,
      };
    });

    const optionnalPrices: MenuPrice[] = spreadOptionnalStepsIds.map(s => {
      const menuStepIds = [...requiredStepsIds, ...s];

      return {
        menuStepIds,
        basePrice: DEFAULT_PRICE_AMOUNT,
      };
    });

    const getStepsPrice = (ids: string[]) => {
      const existingPrice = menu.prices.find(p => isEqual(p.menuStepIds, ids));
      if (existingPrice) {
        return existingPrice.basePrice;
      }
      return DEFAULT_PRICE_AMOUNT;
    };

    const uniqifyPrices = (steps: MenuPrice[]) => {
      const unique: MenuPrice[] = [];
      steps.forEach(s => {
        const existing = unique.find(u => isEqual(u.menuStepIds, s.menuStepIds));
        if (!existing) {
          unique.push(s);
        }
      });
      return unique;
    };

    let prices = [requiredStepsPrice, ...requiredOptionnalSteps, ...optionnalPrices];

    prices = uniqifyPrices(prices);

    prices = prices
      .map(p => {
        const basePrice = getStepsPrice(p.menuStepIds);
        return {
          ...p,
          basePrice,
        };
      })
      .filter(p => p.menuStepIds.length > 0);

    setMenu(prev => ({
      ...prev,
      prices,
    }));
  };

  const handleGetPricingRates = async () => {
    try {
      const { data } = await getPricingRates({
        variables: {
          pagination: {
            limit: 40,
            offset: 0,
          },
        },
      });

      setPricingRates(data?.getPricingRates || []);
    } catch (err) {
      console.log("err getting pricing rates", err);
    }
  };

  useEffect(() => {
    handleGenerateMenuPrices();
  }, [menu.savedMenuSteps]);

  const tags = useMemo(
    () => menu.tags.map(tag => ({ key: tag, label: tag })),
    [menu.tags],
  );

  const updateMenuData = (key: keyof MenuFragment, value: string | boolean) => {
    setMenu(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const getStartTime = () => {
    const { baseAvailability } = menu;

    const isRecurring = baseAvailability?.isRecurring || false;

    const time = isRecurring
      ? baseAvailability?.recurringDates?.startTime
      : baseAvailability?.specificDates?.startTime;

    if (!time) {
      return format(new Date(), "HH:mm");
    }

    return time;
  };
  const getEndTime = () => {
    const { baseAvailability } = menu;

    const isRecurring = baseAvailability?.isRecurring || false;

    const time = isRecurring
      ? baseAvailability?.recurringDates?.endTime
      : baseAvailability?.specificDates?.endTime;

    if (!time) {
      return format(new Date(), "HH:mm");
    }

    return time;
  };

  const updateDays = (days: number[]) => {
    setMenu(prev => {
      const { baseAvailability } = prev;
      return {
        ...prev,
        baseAvailability: {
          ...baseAvailability,
          recurringDates: {
            ...baseAvailability?.recurringDates,
            days,
          },
        },
      };
    });
  };

  const updateSpecificDates = (type: "startDate" | "endDate", date: Date | undefined) => {
    if (date) {
      setMenu(prev => {
        const { baseAvailability } = prev;
        return {
          ...prev,
          baseAvailability: {
            ...baseAvailability,
            specificDates: {
              ...baseAvailability?.specificDates,
              [type]: date?.toISOString(),
            },
          },
        };
      });
    }
  };

  const updateTimes = (type: "startTime" | "endTime", time: string) => {
    setMenu(prev => {
      const { baseAvailability } = prev;
      return {
        ...prev,
        baseAvailability: {
          ...baseAvailability,
          specificDates: {
            ...baseAvailability?.specificDates,
            [type]: time,
          },
          recurringDates: {
            ...baseAvailability?.recurringDates,
            [type]: time,
          },
        },
      };
    });
  };

  const updateIsRecurring = (isRecurring: boolean) => {
    setMenu(prev => {
      const { baseAvailability } = prev;
      return {
        ...prev,
        baseAvailability: {
          ...baseAvailability,
          isRecurring,
        },
      };
    });
  };

  const updateTags = (newTags: { key: string; label: string }[]) => {
    setMenu(prev => ({
      ...prev,
      tags: newTags.map(tag => tag.label),
    }));
  };

  const isEqual = (a: string[], b: string[]) => {
    const sortedA = a.slice().sort();
    const sortedB = b.slice().sort();

    return JSON.stringify(sortedA) === JSON.stringify(sortedB);
  };

  const updateStepPrice = (stepIds: string[], amount: number) => {
    setMenu(prev => {
      const prices = prev.prices.map(price => {
        const { menuStepIds } = price;

        if (isEqual(menuStepIds, stepIds)) {
          return {
            ...price,
            basePrice: {
              amount,
              isEnabled: true,
            },
          };
        }

        return price;
      });

      return {
        ...prev,
        prices,
      };
    });
  };

  const handleGetMenu = async () => {
    try {
      if (id) {
        const { data } = await getMenu({
          variables: { menuId: id },
        });
        if (data?.getMenu) {
          setMenu(data?.getMenu);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPricingRates();
    handleGetMenu();
  }, []);

  const validateForm = async () => {
    try {
      await schema.validate(menu, { abortEarly: false });

      return true;
    } catch (err) {
      setErrors(formaYupErrors(err));

      infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await validateForm();

    if (!isFormValid) return;

    try {
      const { savedMenuSteps, pricingRatePrices = [] } = menu;
      const steps = savedMenuSteps.map(step => ({
        ...step,
        products: step.products.map(p => p._id),
      }));

      const consolidatedPricingRates = pricingRatePrices.map(pr => {
        const rateId =
          typeof pr.pricingRate === "string" ? pr.pricingRate : pr.pricingRate?._id;

        return {
          ...pr,
          pricingRate: rateId,
        };
      });
      const consoldiatedMenu = removeTypeNames({
        ...menu,
        savedMenuSteps: steps,
        pricingRatePrices: consolidatedPricingRates,
      });

      if (isNew) {
        await createMenu({
          variables: {
            menu: consoldiatedMenu,
          },
          refetchQueries: [
            {
              query: GetMenusDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      } else {
        const updates = removeTypeNames(consoldiatedMenu);
        delete updates._id;

        await updateMenu({
          variables: {
            menuId: id,
            updates,
          },
          refetchQueries: [
            {
              query: GetMenusDocument,
              variables: { pagination: { limit: 10, offset: 0 } },
            },
          ],
        });
      }

      handleGoBack();
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "submit",
            path: "submit",
            message: err.message,
          },
        ],
        "error",
      );
      console.log("err submit", err, JSON.stringify(err, null, 2));

      setErrors([
        {
          code: "submit",
          path: "submit",
          message: err.message,
        },
      ]);
    }
  };

  const doesInputHaveError = (key: string) => {
    return errors.some(err => err.path === key);
  };

  const handleDelete = async () => {
    try {
      if (id) {
        await deleteMenu({
          variables: {
            menuId: id,
          },
          // refetchQueries: [
          //   {
          //     query: GetMenusDocument,
          //     variables: { pagination: { limit: 10, offset: 0 } },
          //   },
          // ],
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "Menu",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });

        handleGoBack();
      }
    } catch (error) {
      console.log(error);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERROR_DELETE_MENU",
            path: "ERROR_DELETE_MENU",
            message: "Une erreur est survenue lors de la suppression du menu",
          },
        ],
        "error",
      );
    } finally {
    }
  };

  const handleAddEditStepSubmit = (stepId: string, step: MenuStepFragment) => {
    setMenu(prev => {
      let steps = menu.savedMenuSteps;

      if (stepId) {
        steps = steps.map(s => {
          if (s._id === stepId) {
            return step;
          }
          return s;
        });
      } else {
        steps = [...steps, step];
      }

      return {
        ...prev,
        savedMenuSteps: steps,
      };
    });
  };

  const handleStepDelete = (stepId: string) => {
    setMenu(prev => {
      const steps = prev.savedMenuSteps.filter(s => s._id !== stepId);
      return {
        ...prev,
        savedMenuSteps: steps,
      };
    });
  };

  const handleAddEditStep = (step?: MenuStepFragment) => {
    navigation.navigate("MENUS_LIST_DETAILS_STEP", {
      selectedStep: step,
      onSubmit: handleAddEditStepSubmit,
      onDelete: handleStepDelete,
    });
  };

  const handleUpdateStepEnabled = (stepId: string, isEnabled: boolean) => {
    setMenu(prev => {
      const steps = prev.savedMenuSteps.map(s => {
        if (s._id === stepId) {
          return {
            ...s,
            isEnabled,
          };
        }
        return s;
      });
      return {
        ...prev,
        savedMenuSteps: steps,
      };
    });
  };

  const displayStepNames = (ids: string[]) => {
    const steps = menu.savedMenuSteps.filter(s => ids.includes(s._id));
    return steps.map(s => s.name).join(" + ");
  };

  const handleDisplayPricingRates = () => {
    const { pricingRatePrices: selectedPricingRates = [] } = menu;

    const ratesOptions = pricingRates
      .filter(rate => {
        const isRateSelected = selectedPricingRates.some(selectedRate => {
          const rateId =
            typeof selectedRate.pricingRate === "string"
              ? selectedRate.pricingRate
              : selectedRate.pricingRate?._id;
          return rateId === rate._id;
        });

        return !isRateSelected;
      })
      .map(rate => ({
        label: rate.name,
        key: rate._id,
      }));

    return ratesOptions;
  };

  const handlePricingRateSelection = (rates: string[]) => {
    const { prices } = menu;
    const [pricingRate] = rates;

    const selectedPricingRate = pricingRates.find(rate => rate._id === pricingRate);

    if (!selectedPricingRate) return;

    setMenu(prev => ({
      ...prev,
      pricingRatePrices: [
        ...(prev.pricingRatePrices || []),
        {
          pricingRate: selectedPricingRate?._id,
          prices,
        },
      ],
    }));
  };

  const handleUpdatePricingRateAmount = (
    amount: number,
    pricingRateId: string,
    menuStepIds: string[],
  ) => {
    setMenu(prev => ({
      ...prev,
      pricingRatePrices: prev.pricingRatePrices.map(pricingRate => {
        const formattedPricingRateId =
          typeof pricingRate.pricingRate === "string"
            ? pricingRate.pricingRate
            : pricingRate.pricingRate?._id;

        if (formattedPricingRateId === pricingRateId) {
          const { prices } = pricingRate;

          const consolidateMenuPrices = prices.map(price => {
            const { menuStepIds: currentMenuStepIds, ...rest } = price;
            const isStepSelected = isEqual(currentMenuStepIds, menuStepIds);

            if (isStepSelected) {
              return {
                ...price,
                basePrice: {
                  ...rest.basePrice,
                  amount,
                },
              };
            }
            return price;
          });

          return {
            prices: consolidateMenuPrices,
            pricingRate: pricingRateId,
          };
        }
        return pricingRate;
      }),
    }));
  };

  const handleRemovePricingRate = (pricingRateId: string) => {
    setMenu(prev => ({
      ...prev,
      pricingRatePrices: prev.pricingRatePrices.filter(
        pricingRate => pricingRate.pricingRate !== pricingRateId,
      ),
    }));
  };

  if (loading) {
    return <Loader />;
  }

  const handleAddImage = async (image?: ImagePickerAsset) => {
    const picture = image?.base64;
    if (!picture) return;
    try {
      const { data } = await savePicture({ variables: { picture } });

      if (data) {
        setMenu(prev => ({
          ...prev,
          picture: data.savePicture,
        }));
      }
    } catch (err) {
      console.log("err add image", err);
    }
  };

  const displayPicture = () => {
    const { picture } = menu;
    if (!picture)
      return (
        <Box mt="m">
          <CustomButton
            onPress={() => setIsImagePickerOpen(true)}
            buttonVariant="outlineButton"
            borderColor="success"
          >
            <CustomText variant="outlineButtonText" color="success">
              Ajouter une photo
            </CustomText>
          </CustomButton>
        </Box>
      );

    return (
      <Box alignItems="center" justifyContent="center">
        <TouchableOpacity onPress={() => setIsImagePickerOpen(true)}>
          <Image
            source={{
              uri: picture,
            }}
            style={{
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
            }}
          />
        </TouchableOpacity>
      </Box>
    );
  };

  const handleSortSteps = (steps: MenuStepInput[]) => {
    setMenu(prev => ({
      ...prev,
      savedMenuSteps: steps,
    }));
  };

  const displayTitle = isNew ? "Nouvelle formule" : "Modifier la formule";

  return (
    <>
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box flex={1}>
            <Box marginVertical="s">
              <Box>{displayPicture()}</Box>
            </Box>
            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Nom"
                hasErrors={doesInputHaveError("name")}
                initialValue={menu.name}
                onChangeText={t => updateMenuData("name", t)}
                isRequired
              />
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Slug"
                isRequired
                initialValue={menu.slug}
                hasErrors={doesInputHaveError("slug")}
                onChangeText={t => updateMenuData("slug", t)}
              />
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Référence du produit"
                isRequired
                initialValue={menu.productCode}
                hasErrors={doesInputHaveError("productCode")}
                onChangeText={t => updateMenuData("productCode", t)}
              />
            </Box>

            <Box marginVertical="s">
              <CustomTextInput
                placeHolder="Description du produit"
                multiLine
                initialValue={menu.description}
                onChangeText={t => updateMenuData("description", t)}
                boxProps={{
                  minHeight: 100,
                  alignItems: "flex-start",
                  pt: "s",
                }}
              />
            </Box>

            <Box>
              <CustomText
                mb="s"
                variant="label"
                color={doesInputHaveError("color") ? "danger" : "primaryTextColor"}
              >
                Couleur
              </CustomText>
              <ColorListSelect
                selectedColor={menu.color}
                onPress={color => updateMenuData("color", color)}
              />
            </Box>

            <Box mt="m">
              <CustomText
                mb="s"
                variant="content"
                color={doesInputHaveError("savedMenuSteps") ? "danger" : "success"}
                textTransform="uppercase"
              >
                étapes
              </CustomText>

              <DraggableFlatList
                scrollEnabled={false}
                data={menu.savedMenuSteps}
                style={{ width: "100%" }}
                ListHeaderComponent={
                  <TouchableOpacity onPress={() => handleAddEditStep()}>
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mr="s"
                      marginVertical="s"
                    >
                      <Box flex={0.95}>
                        <InlineSelectButton
                          title="Ajouter une étape"
                          options={[]}
                          selectedOptionKeys={[]}
                          isMultiSelect={false}
                          // eslint-disable-next-line @typescript-eslint/no-empty-function
                          onPress={() => {}}
                        />
                      </Box>

                      <PLUS fill={PALETTE.green} width={15} height={15} />
                    </Box>
                  </TouchableOpacity>
                }
                renderItem={({ item, drag }) => (
                  <TouchableOpacity onPress={() => handleAddEditStep(item)}>
                    <MenuStepCard
                      isEnabled={item.isEnabled}
                      name={item.name}
                      nbProducts={item.products.length}
                      // onPress={() => handleAddEditStep(item)}
                      updatedEnabled={isEnabled =>
                        handleUpdateStepEnabled(item._id, isEnabled)
                      }
                      onPressIn={drag}
                    />
                  </TouchableOpacity>
                )}
                keyExtractor={(item, index) => `draggable-item-${index}`}
                onDragEnd={({ data }) => handleSortSteps(data)}
              />
            </Box>

            {menu.savedMenuSteps?.length > 0 && (
              <>
                <Box mt="m">
                  <CustomText
                    mb="s"
                    variant="content"
                    color={doesInputHaveError("prices") ? "danger" : "success"}
                    textTransform="uppercase"
                  >
                    Prix
                  </CustomText>

                  <FlatList
                    scrollEnabled={false}
                    data={menu.prices}
                    style={{ width: "100%" }}
                    renderItem={({ item }) => (
                      <MenuStepPriceCard
                        name={displayStepNames(item.menuStepIds)}
                        price={item.basePrice.amount}
                        updatePrice={price => updateStepPrice(item.menuStepIds, price)}
                      />
                    )}
                    keyExtractor={(item, index) => `draggable-item-${index}`}
                  />
                </Box>
                <Box marginVertical="s">
                  {handleDisplayPricingRates().length > 0 && (
                    <Box>
                      <SelectButton
                        isMultiSelect={false}
                        options={handleDisplayPricingRates()}
                        // hasErrors={doesInputHaveError("basePrice.tax")}
                        selectedOptions={[]}
                        onPress={handlePricingRateSelection}
                        placeHolder="Autres Prix"
                      />
                    </Box>
                  )}
                </Box>
              </>
            )}

            <Box>
              {menu?.pricingRatePrices?.map((pricingRateMenu, idx) => {
                const { pricingRatePrices: productPricingRates } = menu;
                const selectedPricingRate = productPricingRates.find(
                  pr => pr.pricingRate === pricingRateMenu.pricingRate,
                );

                const foundPricingRate = pricingRates.find(
                  pr => pr._id === pricingRateMenu.pricingRate,
                );

                const name =
                  foundPricingRate?.name || selectedPricingRate?.pricingRate?.name;

                const pricingRateId =
                  foundPricingRate?._id || selectedPricingRate?.pricingRate?._id;

                return (
                  <Box key={idx}>
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <CustomText variant="label" color="primaryTextColor">
                        {name}
                      </CustomText>
                      <TouchableOpacity
                        onPress={() =>
                          handleRemovePricingRate(pricingRateMenu.pricingRate)
                        }
                      >
                        <TRASH fill={PALETTE.red} width={15} height={15} />
                      </TouchableOpacity>
                    </Box>
                    <FlatList
                      scrollEnabled={false}
                      data={pricingRateMenu.prices}
                      style={{ width: "100%" }}
                      renderItem={({ item }) => (
                        <MenuStepPriceCard
                          name={displayStepNames(item.menuStepIds)}
                          price={item.basePrice.amount}
                          updatePrice={price => {
                            handleUpdatePricingRateAmount(
                              price,
                              pricingRateId,
                              item.menuStepIds,
                            );
                          }}
                        />
                      )}
                      keyExtractor={(item, index) => `draggable-item-${index}`}
                    />
                  </Box>
                );
              })}
            </Box>

            <Box
              marginVertical="s"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomText
                style={{ flex: 1, flexWrap: "wrap" }}
                variant="label"
                color="primaryTextColor"
              >
                Activé
              </CustomText>
              <Toggle
                value={menu.isEnabled}
                onChange={value => updateMenuData("isEnabled", value)}
              />
            </Box>

            <Box>
              <DateVisibilitySection
                days={menu.baseAvailability.recurringDates.days}
                endDate={new Date(menu.baseAvailability.specificDates.endDate)}
                startDate={new Date(menu.baseAvailability.specificDates.startDate)}
                isRecurring={menu.baseAvailability.isRecurring}
                startTime={getStartTime()}
                endTime={getEndTime()}
                updateDays={val => updateDays(val)}
                updateStartDate={date => updateSpecificDates("startDate", date)}
                updateEndDate={date => updateSpecificDates("endDate", date)}
                updateStartTime={t => updateTimes("startTime", t)}
                updateEndTime={t => updateTimes("endTime", t)}
                updateReccuring={updateIsRecurring}
              />
            </Box>

            <Box mt="m">
              <CustomText mb="s" variant="label" color="primaryTextColor">
                Tags
              </CustomText>

              <ButtonList values={tags} onChange={updateTags} />
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAwareScrollView>

      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        mt="s"
        justifyContent="center"
        minHeight={100}
        p="m"
        pb="l"
        backgroundColor="white"
      >
        {!isNew && (
          <CustomButton
            buttonVariant="outlineButton"
            onPress={handleDelete}
            borderColor="danger"
            styles={{
              mb: "s",
            }}
          >
            <CustomText variant="outlineButtonText" color="danger">
              Supprimer
            </CustomText>
          </CustomButton>
        )}

        <CustomButton
          buttonVariant="primaryButton"
          onPress={handleSubmit}
          buttonColor="success"
        >
          <CustomText variant="primaryButtonText" color="white">
            Enregistrer
          </CustomText>
        </CustomButton>
      </Box>

      <AddNewImageModal
        isOpen={isImagePickerOpen}
        setIsOpen={val => setIsImagePickerOpen(val)}
        onSubmit={handleAddImage}
      />
    </>
  );
};

export default MenuListDetailsEdit;
