/* eslint-disable max-len */
import { useNavigation } from "@react-navigation/native";
import { format, isBefore, isSameHour, isSameMinute } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { useResizeMode } from "react-native-keyboard-controller";
import * as yup from "yup";

import Box from "../../../../../../components/Base/Box";
import { CustomText } from "../../../../../../components/Base/Text";
import BaseService from "../../../../../../components/BookingServiceListDetails/BaseService/index";
import ServiceConfirmation from "../../../../../../components/BookingServiceListDetails/ServiceConfirmation/index";
import ServicePersonsLimits from "../../../../../../components/BookingServiceListDetails/ServicePersonsLimits/index";
import ServicePrepayment from "../../../../../../components/BookingServiceListDetails/ServicePrepayment/index";
import ServiceProductSelection from "../../../../../../components/BookingServiceListDetails/ServiceProductSelection/index";
import ServiceSlots from "../../../../../../components/BookingServiceListDetails/ServiceSlots/index";
import ServiceTablePlacement from "../../../../../../components/BookingServiceListDetails/ServiceTablePlacement/index";
import ServiceVisibility from "../../../../../../components/BookingServiceListDetails/ServiceVisibility/index";
import ServiceWaitingList from "../../../../../../components/BookingServiceListDetails/ServiceWaitingList/index";
import type { BUTTON_ACTION } from "../../../../../../components/BottomButtomWithActions";
import BottomButtomWithActions from "../../../../../../components/BottomButtomWithActions";
import BottomButton from "../../../../../../components/BottomButton";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import InputSectionTitle from "../../../../../../components/InputSectionTitle";
import KeyboardAwareScrollView from "../../../../../../components/KeyboardAwareScrollView";
import Loader from "../../../../../../components/Loader";
import ScreenHeader from "../../../../../../components/ScreenHeader";
import TimeInput from "../../../../../../components/TimeInput";
import ToggleInputLine from "../../../../../../components/ToggleInputLine";
import { ErrorInfoSuccessAlertModalContext } from "../../../../../../contexts/ErrorInfoSuccessAlertModalContext/index";
import type {
  BookingAvailability,
  BookingAvailabilityFragment,
  CreateBookingAvailabilityInput,
} from "../../../../../../graphql/generated/schema";
import {
  PrepaymentCancelDelimiter,
  PrepaymentCancelMesaurementType,
  PrepaymentType,
  useCreateBookingAvailabilityMutation,
  useDeleteBookingAvailabilityMutation,
  useGenerateBookingsSlotsMutation,
  useGetBookingAvailabilityLazyQuery,
  useUpdateBookingAvailabilityMutation,
} from "../../../../../../graphql/generated/schema";
import type { ERROR } from "../../../../../../utils/common";
import {
  createDateWithTime,
  formaYupErrors,
  removeTypeNames,
} from "../../../../../../utils/common";

interface BookingServiceListDetailsEditProps {
  id?: string;
  goBack: () => void;
}

const DEFAULT_BOOKING_AVAILABILITY: CreateBookingAvailabilityInput = {
  automaticConfirmationSettings: {
    isEnabled: false,
    isEnabledForMaxCapacity: false,
    maxCapacityForAutomaticConfirmation: 1,
  },
  isBookingExperienceEnabled: true,
  bookingExperiencesTimeRange: {
    endTime: "12:00",
    startTime: "10:00",
  },
  bookingFloorSettings: {
    minPersons: 1,
    bookingFloors: [],
    isEnabled: false,
    isRequired: false,
    isTableSelectionEnabled: false,
    bookingFloorsCapacityConfiguration: [],
  },
  interval: 15,
  isEnabled: true,
  maxCapacityAllowedForSlot: {
    isEnabled: false,
    value: 1,
  },
  name: "",
  prepaymentSettings: {
    amountForPrepayment: 1,
    automaticPayment: false,
    isEnabled: false,
    minCapacityForPrepayment: 1,
    percentBookingCancel: 1,
    percentBookingNoShow: 1,
    prepaymentCancelSettings: {
      delimiter: PrepaymentCancelDelimiter.Reservation,
      delimiterMesaurementType: PrepaymentCancelMesaurementType.Minutes,
      amount: 10,
    },
    prepaymentType: PrepaymentType.FixedRate,
  },
  totalPersonsAllowed: {
    isEnabled: false,
    value: 1,
  },
  visibilitySetting: {
    isRecurring: true,
    recurringDates: {
      days: [],
      startTime: "10:00",
      endTime: "12:00",
    },
    specificDates: {
      endDate: new Date().toISOString(),
      startDate: new Date().toISOString(),
      startTime: "10:00",
      endTime: "12:00",
    },
  },
  waitingListSettings: {
    isEnabled: false,
    maxPersons: 1,
    minPersons: 1,
    totalPersonsAllowed: 1,
  },
  bookingProductsSettings: {
    categories: [],
    isEnabled: false,
    isPaymentRequired: false,
    isRequired: false,
    shouldOrderQuantityBeLinkedToNbPersons: false,
    menus: [],
    minPersons: 1,
    products: [],
  },
  isBookingExperienceAppliedToCompleteService: true,
  color: "",
  minMaxBookingPersons: {
    isEnabled: false,
    maxPersons: 1,
    minPersons: 1,
  },
};

const schema = yup.object().shape({
  name: yup.string().required("Le nom est obligatoire"),
  color: yup.string().required("La couleur est obligatoire"),
  isEnabled: yup.boolean().required("Le statut est obligatoire"),
  interval: yup.number().min(1).required("L'intervalle est obligatoire"),
  visibilitySetting: yup.object().shape({
    isRecurring: yup.boolean().required(),
    recurringDates: yup.object().when("isRecurring", {
      is: true,
      then: yup.object().shape({
        days: yup
          .array()
          .of(yup.number())
          .min(1, "Un jour doit être sélectionné")
          .required("Un jour doit être sélectionné"),
        startTime: yup.string().required(),
        endTime: yup.string().required(),
      }),
      otherwise: yup.object().shape({
        days: yup.array().of(yup.number()),
        startTime: yup.string().required(),
        endTime: yup.string().required(),
      }),
    }),
    specificDates: yup.object().shape({
      startDate: yup.string().required(),
      endDate: yup.string().required(),
      startTime: yup.string().required(),
      endTime: yup
        .string()
        .required()
        .test(
          "is-end-time-after-start",
          "L'heure de fin doit etre supérieur à l'heure de début",
          function (endTime) {
            const { startTime } = this.parent;

            if (!endTime) return false;

            const endDate = createDateWithTime(new Date(), endTime);
            const startDate = createDateWithTime(new Date(), startTime);

            const isValid =
              isBefore(endDate, startDate) ||
              (isSameHour(startDate, endDate) && isSameMinute(startDate, endDate));

            return !isValid;
          },
        ),
    }),
  }),
  totalPersonsAllowed: yup.object().shape({
    isEnabled: yup.boolean().required(),
    value: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(1, "La capacité total de l'établissement doit etre supérieur à 0")
        .required("La capacité total de l'établissement doit etre supérieur à 0"),
    }),
  }),
  maxCapacityAllowedForSlot: yup.object().shape({
    isEnabled: yup.boolean().required(),
    value: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(1, "La capacité par créneau doit etre supérieur à 0")
        .required("La capacité par créneau doit etre supérieur à 0"),
    }),
  }),
  minMaxBookingPersons: yup.object().shape({
    isEnabled: yup.boolean().required(),
    minPersons: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(1, "Le nombre minimum de personnes doit etre supérieur à 0")
        // .min(
        //   yup.ref("minMaxBookingPersons.maxPersons"),
        //   "Le nombre minimum de personnes doit etre inférieur au nombre maximum de personnes",
        // )
        .required("Le nombre minimum de personnes doit etre supérieur à 0"),
    }),
    maxPersons: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()

        .min(1, "Le nombre maximum de personnes doit etre supérieur à 0")
        .required("Le nombre maximum de personnes doit etre supérieur à 0"),
    }),
  }),
  automaticConfirmationSettings: yup.object().shape({
    isEnabled: yup.boolean().required(),
    isEnabledForMaxCapacity: yup.boolean().required(),
    maxCapacityForAutomaticConfirmation: yup.number().when("isEnabledForMaxCapacity", {
      is: true,
      then: yup
        .number()
        .min(1, "La capacité pour la confirmation automatique doit etre supérieur à 0"),
    }),
  }),
  prepaymentSettings: yup.object().shape({
    isEnabled: yup.boolean().required(),
    automaticPayment: yup.boolean().required(),
    amountForPrepayment: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(1, "Le montant pour l'empreinte bancaire doit etre supérieur à 0"),
    }),
    minCapacityForPrepayment: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(1, "La capacité pour l'empreinte bancaire doit etre supérieur à 0"),
    }),
    percentBookingCancel: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(0, "Le pourcentage d'annulation doit etre supérieur ou égal à 0")
        .max(100, "Le pourcentage d'annulation doit etre inférieur à 100"),
    }),
    percentBookingNoShow: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(0, "Le pourcentage de no-show doit etre supérieur ou égal à 0")
        .max(100, "Le pourcentage de no-show doit etre inférieur à 100"),
    }),
    prepaymentCancelSettings: yup.object().shape({
      delimiter: yup.string().required(),
      delimiterMesaurementType: yup.string().required(),
      amount: yup.number().when("isEnabled", {
        is: true,
        then: yup
          .number()
          .min(1, "Le délai pour l'annulation/no-show doit etre supérieur à 0"),
      }),
    }),
    prepaymentType: yup.string().required(),
  }),
  bookingFloorSettings: yup.object().shape({
    isEnabled: yup.boolean().required(),
    isRequired: yup.boolean().required(),
    minPersons: yup.number().when("isEnabled", {
      is: true,
      then: yup.number().min(1, "Le nombre minimum de personnes doit etre supérieur à 0"),
    }),
    bookingFloors: yup
      .array()
      .of(yup.string())
      .when("isEnabled", {
        is: true,
        then: yup
          .array()
          .of(yup.string())
          .min(
            1,
            "Une salle au moins doit être sélectionné pour le placement des clients",
          ),
      }),
  }),
  bookingProductsSettings: yup.object().shape({
    isEnabled: yup.boolean().required(),
    isRequired: yup.boolean().required(),
    isPaymentRequired: yup.boolean().required(),
    minPersons: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(
          1,
          "Le nombre minimum de personnes pour la séléction des produits doit etre supérieur à 0",
        ),
    }),
  }),
  waitingListSettings: yup.object().shape({
    isEnabled: yup.boolean().required(),
    minPersons: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(
          1,
          "Le nombre minimum de personnes dans la liste d'attente doit etre supérieur à 0",
        ),
    }),
    maxPersons: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(
          1,
          "Le nombre maximum de personnes dans la liste d'attente doit etre supérieur à 0",
        ),
    }),
    totalPersonsAllowed: yup.number().when("isEnabled", {
      is: true,
      then: yup
        .number()
        .min(
          1,
          "Le nombre maximum de personnes dans la liste d'attente doit etre supérieur à 0",
        ),
    }),
  }),
});

const BookingServiceListDetailsEdit = ({
  id,
  goBack,
}: BookingServiceListDetailsEditProps) => {
  const isNew = !id;
  useResizeMode();
  const [loading, setLoading] = useState(!isNew);
  const navigation = useNavigation();
  const [errors, setErrors] = useState<ERROR[]>([]);
  const [previousService, setPreviousService] = useState<
    BookingAvailabilityFragment | undefined
  >(undefined);

  const infoAlert = useContext(ErrorInfoSuccessAlertModalContext);

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
  };

  const [bookingAvailability, setBookingAvailability] =
    useState<BookingAvailabilityFragment>(DEFAULT_BOOKING_AVAILABILITY);

  const [getBookingAvailability] = useGetBookingAvailabilityLazyQuery();
  const [createBookingAvailbility] = useCreateBookingAvailabilityMutation();
  const [updateBookingAvailability] = useUpdateBookingAvailabilityMutation();
  const [deleteBookingAvailability] = useDeleteBookingAvailabilityMutation();

  const [generateBookingSlots] = useGenerateBookingsSlotsMutation();

  const handleGetBookingAvailability = async () => {
    try {
      if (!isNew) {
        const { data } = await getBookingAvailability({
          variables: {
            availabilityId: id,
          },
        });

        if (data?.getBookingAvailability) {
          const service = {
            ...data.getBookingAvailability,
            isBookingExperienceEnabled:
              data?.getBookingAvailability?.isBookingExperienceEnabled === null
                ? true
                : data?.getBookingAvailability?.isBookingExperienceEnabled,
          };

          setBookingAvailability(service);
          setPreviousService(service);
        }
      } else {
        setBookingAvailability(DEFAULT_BOOKING_AVAILABILITY);
      }
    } catch (err) {
      console.log("err get booking availability", err);
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_GET_BOOKING_AVAILABILITY",
            message: "Une erreur est survenue lors de la récupération des données",
          },
        ],
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBookingAvailability();
  }, []);

  const handleConsolidateBookingAvailability = () => {
    let bookingAvailabilityToSave = removeTypeNames(bookingAvailability);
    delete bookingAvailabilityToSave._id;

    const { bookingFloorSettings, bookingProductsSettings } = bookingAvailability;

    const consolidatedBookingFloorSettings = {
      ...bookingFloorSettings,
      bookingFloors: bookingFloorSettings?.bookingFloors.map(floor => floor._id),
    };

    const consolidatedBookingProductsSettings = {
      ...bookingProductsSettings,
      categories: bookingProductsSettings?.categories.map(category => category._id),
      menus: bookingProductsSettings?.menus.map(menu => menu._id),
      products: bookingProductsSettings?.products.map(product => product._id),
    };

    bookingAvailabilityToSave = {
      ...bookingAvailabilityToSave,
      bookingFloorSettings: consolidatedBookingFloorSettings,
      bookingProductsSettings: consolidatedBookingProductsSettings,
    };

    return bookingAvailabilityToSave;
  };

  const canViewSlots = async () => {
    try {
      const consolidatedBookingAvailability = handleConsolidateBookingAvailability();

      const isFormValid = await validateForm(consolidatedBookingAvailability);

      return isFormValid;
    } catch (_err) {}
    return false;
  };

  const validateForm = async (consolidatedService: CreateBookingAvailabilityInput) => {
    try {
      await schema.validate(consolidatedService, { abortEarly: false });

      const {
        totalPersonsAllowed,
        minMaxBookingPersons,
        maxCapacityAllowedForSlot,
        automaticConfirmationSettings,
        waitingListSettings,
        bookingProductsSettings,
        bookingFloorSettings,
        isBookingExperienceAppliedToCompleteService,
        bookingExperiencesTimeRange,
        visibilitySetting,
      } = consolidatedService;

      // SERVICE LIMITS
      if (
        totalPersonsAllowed.isEnabled &&
        maxCapacityAllowedForSlot.isEnabled &&
        maxCapacityAllowedForSlot.value > totalPersonsAllowed.value
      ) {
        throw new Error(
          "Le nombre maximum de personnes par créneau ne peut pas être supérieur au nombre total de personnes autorisées",
        );
      }

      if (
        maxCapacityAllowedForSlot.isEnabled &&
        minMaxBookingPersons.isEnabled &&
        minMaxBookingPersons.maxPersons > maxCapacityAllowedForSlot.value
      ) {
        throw new Error(
          "Le nombre maximum de personnes par réservation ne peut pas être supérieur au nombre maximum de personnes par créneau",
        );
      }

      if (
        minMaxBookingPersons.isEnabled &&
        minMaxBookingPersons.minPersons > minMaxBookingPersons.maxPersons
      ) {
        throw new Error(
          "Le nombre minimum de personnes par réservation ne peut pas être supérieur au nombre maximum de personnes par réservation",
        );
      }

      // SERVICE AUTO CONFIRM
      if (
        maxCapacityAllowedForSlot.isEnabled &&
        automaticConfirmationSettings.isEnabledForMaxCapacity &&
        automaticConfirmationSettings.maxCapacityForAutomaticConfirmation >
          maxCapacityAllowedForSlot.value
      ) {
        throw new Error(
          "Le nombre maximum de personnes par créneau ne peut pas être inférieur au nombre de personnes pour la confirmation automatique",
        );
      }

      // SERVICE WAITING LIST

      if (
        waitingListSettings.isEnabled &&
        waitingListSettings.maxPersons > waitingListSettings.totalPersonsAllowed
      ) {
        throw new Error(
          "Le nombre maximum de personnes dans la liste d'attente ne peut pas être supérieur au nombre total de personnes autorisées",
        );
      }

      if (
        waitingListSettings.isEnabled &&
        waitingListSettings.minPersons > waitingListSettings.maxPersons
      ) {
        throw new Error(
          "Le nombre minimum de personnes dans la liste d'attente ne peut pas être supérieur au nombre maximum de personnes dans la liste d'attente",
        );
      }

      // SERVICE BOOKING PRODUCTS SETTINGS

      if (
        bookingProductsSettings.isEnabled &&
        bookingProductsSettings.categories.length === 0 &&
        bookingProductsSettings.menus.length === 0 &&
        bookingProductsSettings.products.length === 0
      ) {
        throw new Error(
          "Vous devez sélectionner au moins une catégorie, un menu ou un produit",
        );
      }

      // SERVICE BOOKING FLOOR SETTINGS

      if (
        bookingFloorSettings.isEnabled &&
        bookingFloorSettings.bookingFloors.length === 0
      ) {
        throw new Error("Vous devez sélectionner au moins un plan de salle");
      }

      // SERVICE EXPERIENCES TIME RANGE

      if (!isBookingExperienceAppliedToCompleteService) {
        const startExperienceTime = createDateWithTime(
          new Date(),
          bookingExperiencesTimeRange.startTime,
        );
        const endExperienceTime = createDateWithTime(
          new Date(),
          bookingExperiencesTimeRange.endTime,
        );

        if (isBefore(endExperienceTime, startExperienceTime)) {
          throw new Error(
            "L'heure de fin de l'expérience ne peut pas être inférieure à l'heure de début de l'expérience",
          );
        }
      }

      // SERVICE VISIBILITY SETTINGS
      if (visibilitySetting.isRecurring) {
        const startTime = createDateWithTime(
          new Date(),
          visibilitySetting.recurringDates.startTime,
        );
        const endTime = createDateWithTime(
          new Date(),
          visibilitySetting.recurringDates.endTime,
        );

        if (isBefore(endTime, startTime)) {
          throw new Error(
            "L'heure de fin du service ne peut pas être inférieure à l'heure de début du service",
          );
        }
      }

      return true;
    } catch (err) {
      if (err?.inner) {
        setErrors(formaYupErrors(err));

        infoAlert.openAlert("Erreur", formaYupErrors(err), "error");
      } else {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "ERR_VALIDATE_FORM",
              message:
                err.message ||
                "Une erreur est survenue lors de la validation du formulaire",
            },
          ],
          "error",
        );
      }
    }
    return false;
  };

  const handleSubmit = async () => {
    try {
      let consolidatedBookingAvailability = handleConsolidateBookingAvailability();

      const isFormValid = await validateForm(consolidatedBookingAvailability);

      if (!isFormValid) return;

      if (isNew) {
        const { slots } = bookingAvailability;

        if (!slots || slots.length === 0) {
          const generatedSlots = await handleGenerateSlots();
          consolidatedBookingAvailability = {
            ...consolidatedBookingAvailability,
            slots: generatedSlots,
          };

          consolidatedBookingAvailability = removeTypeNames(
            consolidatedBookingAvailability,
          );
        }

        await createBookingAvailbility({
          variables: {
            bookingAvailability: consolidatedBookingAvailability,
          },
        });
      }
      handleGoBack();
    } catch (err) {
      if (err?.message === "CONFLICTING_AVAILABILITY") {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "CONFLICTING_AVAILABILITY",
              message: "Une disponibilité existe avec les mêmes paramètres",
            },
          ],
          "error",
        );
      } else {
        console.log("err service submit", JSON.stringify(err, null, 2));

        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "ERR_CREATE_UPDATE_BOOKING_AVAILABILITY",
              message:
                "Une erreur est survenue lors de la sauvegarde de la disponibilité",
            },
          ],
          "error",
        );
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (id) {
        await deleteBookingAvailability({
          variables: {
            availabilityId: id,
          },
          update: cache => {
            const normalizedId = cache.identify({
              _id: id,
              __typename: "BookingAvailability",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });

        handleGoBack();
      }
    } catch (err) {
      console.log("err delete", err);

      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_DELETE_BOOKING_AVAILABILITY",
            message: "Une erreur est survenue lors de la suppression de la disponibilité",
          },
        ],
        "error",
      );
    }
  };

  const checkIfArraysHaveSameContent = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) return false;

    return arr1.every(item => arr2.includes(item));
  };

  const shouldGenerateNewSlots = () => {
    if (!previousService) return true;

    const {
      visibilitySetting,
      automaticConfirmationSettings,
      waitingListSettings,
      maxCapacityAllowedForSlot,
      minMaxBookingPersons,
      totalPersonsAllowed,
      interval,
    } = bookingAvailability;

    if (previousService.interval !== interval) return true;

    if (
      visibilitySetting.isRecurring !== previousService.visibilitySetting.isRecurring ||
      visibilitySetting.recurringDates.startTime !==
        previousService.visibilitySetting.recurringDates.startTime ||
      visibilitySetting.recurringDates.endTime !==
        previousService.visibilitySetting.recurringDates.endTime ||
      visibilitySetting.specificDates.startDate !==
        previousService.visibilitySetting.specificDates.startDate ||
      visibilitySetting.specificDates.endDate !==
        previousService.visibilitySetting.specificDates.endDate ||
      visibilitySetting.specificDates.startTime !==
        previousService.visibilitySetting.specificDates.startTime ||
      visibilitySetting.specificDates.endTime !==
        previousService.visibilitySetting.specificDates.endTime ||
      !checkIfArraysHaveSameContent(
        visibilitySetting.recurringDates.days,
        previousService.visibilitySetting.recurringDates.days,
      )
    ) {
      return true;
    }

    if (
      automaticConfirmationSettings?.isEnabled !==
        previousService?.automaticConfirmationSettings?.isEnabled ||
      automaticConfirmationSettings?.isEnabledForMaxCapacity !==
        previousService?.automaticConfirmationSettings?.isEnabledForMaxCapacity ||
      automaticConfirmationSettings?.maxCapacityForAutomaticConfirmation !==
        previousService?.automaticConfirmationSettings
          ?.maxCapacityForAutomaticConfirmation
    ) {
      return true;
    }

    if (
      waitingListSettings?.isEnabled !==
        previousService?.waitingListSettings?.isEnabled ||
      waitingListSettings?.maxPersons !==
        previousService?.waitingListSettings?.maxPersons ||
      waitingListSettings?.minPersons !==
        previousService?.waitingListSettings?.minPersons ||
      waitingListSettings?.totalPersonsAllowed !==
        previousService?.waitingListSettings?.totalPersonsAllowed
    ) {
      return true;
    }

    if (
      maxCapacityAllowedForSlot?.isEnabled !==
        previousService?.maxCapacityAllowedForSlot?.isEnabled ||
      maxCapacityAllowedForSlot?.value !==
        previousService?.maxCapacityAllowedForSlot?.value ||
      minMaxBookingPersons?.isEnabled !==
        previousService?.minMaxBookingPersons?.isEnabled ||
      minMaxBookingPersons?.minPersons !==
        previousService?.minMaxBookingPersons?.minPersons ||
      minMaxBookingPersons?.maxPersons !==
        previousService?.minMaxBookingPersons?.maxPersons ||
      totalPersonsAllowed?.isEnabled !==
        previousService?.totalPersonsAllowed?.isEnabled ||
      totalPersonsAllowed?.value !== previousService?.totalPersonsAllowed?.value
    )
      return true;

    return false;
  };

  const handleMainButtonPress = async () => {
    try {
      if (id) {
        const consolidatedBookingAvailability = handleConsolidateBookingAvailability();

        const isFormValid = await validateForm(consolidatedBookingAvailability);

        if (!isFormValid) return;

        let updates = {
          ...consolidatedBookingAvailability,
        };

        if (shouldGenerateNewSlots()) {
          const generatedSlots = await handleGenerateSlots();

          updates.slots = generatedSlots;
        }

        updates = removeTypeNames(updates);
        delete updates._id;

        await updateBookingAvailability({
          variables: {
            availabilityId: id,
            bookingAvailability: updates,
          },
        });
      }

      handleGoBack();
    } catch (err) {
      console.log("err", err);

      if (err?.message === "CONFLICTING_AVAILABILITY") {
        infoAlert.openAlert(
          "Erreur",
          [
            {
              code: "CONFLICTING_AVAILABILITY",
              message: "Une disponibilité existe avec les mêmes paramètres",
            },
          ],
          "error",
        );
      } else {
        infoAlert.openAlert(
          "Erreur création de service",
          [
            {
              code: "ERR_UPDATE_SERVICE",
              message: "Une erreur est survenue lors de la sauvegarde du service",
            },
          ],
          "error",
        );
      }
    }
  };

  const handleActionPress = async (key: string) => {
    try {
      if (key === "DELETE") handleDelete();
    } catch (err) {
      infoAlert.openAlert(
        "Erreur",
        [
          {
            code: "ERR_UNKOWN",
            message: "Une erreur est survenue.",
          },
        ],
        "error",
      );
    }
  };

  const getButtonActions = (): BUTTON_ACTION[] => {
    return [
      {
        title: "SUPPRIMER LE SERVICE",
        key: "DELETE",
        variant: "outline",
        color: "danger",
      },
    ];
  };

  const handleGenerateSlots = async () => {
    try {
      let consolidatedBookingService = handleConsolidateBookingAvailability();

      consolidatedBookingService = removeTypeNames(consolidatedBookingService);

      const { data } = await generateBookingSlots({
        variables: {
          bookingAvailability: consolidatedBookingService,
        },
      });

      return data?.generatedBookingSlots || [];
    } catch (err) {
      console.log("err generate slots", JSON.stringify(err, null, 2));
    }
    return [];
  };

  const updateBookingAvailabilityData = (
    key: keyof BookingAvailability,
    value: BookingAvailability[keyof BookingAvailability],
  ) => {
    setBookingAvailability(prev => ({ ...prev, [key]: value }));
  };

  const updateBookingExperienceTimeRange = (
    type: "startTime" | "endTime",
    value: Date | undefined,
  ) => {
    if (value) {
      const time = format(value, "HH:mm");
      setBookingAvailability(prev => ({
        ...prev,
        bookingExperiencesTimeRange: {
          ...prev.bookingExperiencesTimeRange,
          [type]: time,
        },
      }));
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!bookingAvailability) {
    return (
      <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
        <ScreenHeader title="Paramètres" hasBackButton onBackPress={handleGoBack} />

        <ErrorMessage message="Une erreur est survenue lors de la récupération des paramètres de réservation." />
      </Box>
    );
  }

  const checkIfBookingExperienceIsEnabled = () => {
    if (bookingAvailability.isBookingExperienceEnabled === true) {
      return true;
    }

    if (
      typeof bookingAvailability.isBookingExperienceEnabled === "undefined" ||
      bookingAvailability.isBookingExperienceEnabled === null
    ) {
      return true;
    }

    return false;
  };

  const displayTitle = isNew ? "Nouveau service" : "Modifier le service";

  return (
    <Box flex={1} backgroundColor="white">
      <Box marginVertical="s">
        <ScreenHeader title={displayTitle} hasBackButton onBackPress={handleGoBack} />
      </Box>

      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingBottom: 150,
          }}
        >
          <Box>
            <BaseService
              service={bookingAvailability}
              setService={setBookingAvailability}
              errors={errors}
            />

            <ServiceVisibility
              bookingAvailability={bookingAvailability}
              setBookingAvailability={setBookingAvailability}
              errors={errors}
            />

            <Box mt="m">
              <InputSectionTitle text="PARAMÈTRES AVANCÉS" />
            </Box>

            <ServicePersonsLimits
              bookingAvailability={bookingAvailability}
              setBookingAvailability={setBookingAvailability}
              errors={errors}
            />

            <ServiceConfirmation
              bookingAvailability={bookingAvailability}
              setBookingAvailability={setBookingAvailability}
              errors={errors}
            />

            <ServicePrepayment
              bookingAvailability={bookingAvailability}
              setBookingAvailability={setBookingAvailability}
              errors={errors}
            />

            <ServiceWaitingList
              bookingAvailability={bookingAvailability}
              setBookingAvailability={setBookingAvailability}
              errors={errors}
            />

            <Box mt="m">
              <InputSectionTitle text="EXPERIENCES" />
              <CustomText variant="content" color="lightGrey">
                Allez encore plus dans le concept et proposez expérience à vos clients
              </CustomText>
            </Box>

            <Box mt="m">
              <ToggleInputLine
                textVariant="content"
                text="Activer les expériences"
                value={!!bookingAvailability.isBookingExperienceEnabled}
                onValueChange={v =>
                  updateBookingAvailabilityData("isBookingExperienceEnabled", v)
                }
              />
            </Box>

            {checkIfBookingExperienceIsEnabled() && (
              <>
                <Box mt="m">
                  <ToggleInputLine
                    textVariant="content"
                    text="Utilisez l'intégralité du service"
                    value={
                      bookingAvailability.isBookingExperienceAppliedToCompleteService
                    }
                    onValueChange={v =>
                      updateBookingAvailabilityData(
                        "isBookingExperienceAppliedToCompleteService",
                        v,
                      )
                    }
                  />

                  {!bookingAvailability.isBookingExperienceAppliedToCompleteService && (
                    <Box mt="s" flexDirection="row" alignItems="center">
                      <Box flex={0.5} />
                      <Box flex={0.5} flexDirection="row" alignItems="center">
                        <Box flex={0.5} mr="s">
                          <TimeInput
                            date={createDateWithTime(
                              new Date(),
                              bookingAvailability?.bookingExperiencesTimeRange?.startTime,
                            )}
                            onChange={date =>
                              updateBookingExperienceTimeRange("startTime", date)
                            }
                            placeHolder="Début"
                            disabled={false}
                          />
                        </Box>
                        <Box flex={0.5}>
                          <TimeInput
                            date={createDateWithTime(
                              new Date(),
                              bookingAvailability?.bookingExperiencesTimeRange?.endTime,
                            )}
                            onChange={date =>
                              updateBookingExperienceTimeRange("endTime", date)
                            }
                            placeHolder="Fin"
                            disabled={false}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                <ServiceTablePlacement
                  bookingAvailability={bookingAvailability}
                  setBookingAvailability={setBookingAvailability}
                  errors={errors}
                />

                <ServiceProductSelection
                  bookingAvailability={bookingAvailability}
                  setBookingAvailability={setBookingAvailability}
                  errors={errors}
                />
              </>
            )}
          </Box>

          <ServiceSlots
            bookingAvailability={bookingAvailability}
            setBookingAvailability={setBookingAvailability}
            id={id}
            onPressSlots={canViewSlots}
          />
        </ScrollView>
      </KeyboardAwareScrollView>

      {isNew ? (
        <BottomButton title="Enregistrer" onPress={handleSubmit} />
      ) : (
        <BottomButtomWithActions
          title="Enregistrer"
          textVariant="primaryButtonText"
          // backGroundColor="success"
          // color="white"
          // iconColor="white"
          actions={getButtonActions()}
          onMainActionPress={handleMainButtonPress}
          onSubmit={handleActionPress}
        />
      )}
    </Box>
  );
};

export default BookingServiceListDetailsEdit;
