/* eslint-disable @typescript-eslint/no-empty-function */

import { useNavigation } from "@react-navigation/native";
import { isSameDay } from "date-fns";
import { FlatList, Platform } from "react-native";

import CALENDAR from "../../../assets/icons/BASE/CALENDAR.svg";
import CUTLERY from "../../../assets/icons/BASE/CUTLERY.svg";
import type {
  SelectedBookingTableFragment,
  TableFragment,
} from "../../graphql/generated/schema";
import {
  Booking_Status,
  Module_Listing_Client_Format,
} from "../../graphql/generated/schema";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import { CustomText } from "../Base/Text";
import BookingListCard from "../BookingList/BookingListCard";
import { CustomButton } from "../Button";
import ContentModal from "../ContentModal";

const IS_WEB = Platform.OS === "web";

interface BookingFloorTableViewModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  table?: TableFragment;
  handleRefreshTable: () => void;
  selectedBookingServiceId?: string;
  selectedDate?: Date;
}

const BookingFloorTableViewModal = ({
  isOpen,
  setIsOpen,
  table,
  handleRefreshTable,
  selectedBookingServiceId,
  selectedDate,
}: BookingFloorTableViewModalProps) => {
  const navigation = useNavigation();
  const isCurrentDaySameAsSelectedDay = selectedDate
    ? isSameDay(new Date(), new Date(selectedDate))
    : false;

  const handleClose = () => {
    setIsOpen(false);
  };

  const displayTitle = () => {
    if (table) {
      return `${table?.prefix}-${table.tableNumber} `;
    }
    return "";
  };

  const handleCreateBooking = () => {
    setIsOpen(false);

    if (!table) {
      navigation.navigate("NEW_BOOKING");
      return;
    }

    const selectedTable: SelectedBookingTableFragment = {
      floorId: table.floorId,
      tableId: table._id,
      prefix: table.prefix,
      tableNumber: table.tableNumber,
      minCapacity: table.minCapacity,
      maxCapacity: table.maxCapacity,
    };

    navigation.navigate("NEW_BOOKING", {
      tables: [selectedTable],
    });
  };

  const handleNewLocalBooking = () => {
    setIsOpen(false);

    const selectedTable: SelectedBookingTableFragment = {
      floorId: table.floorId,
      tableId: table._id,
      prefix: table.prefix,
      tableNumber: table.tableNumber,
      minCapacity: table.minCapacity,
      maxCapacity: table.maxCapacity,
    };

    navigation.navigate("NEW_LOCAL_BOOKING", {
      tables: [selectedTable],
    });
  };

  const baseBookings = table?.bookings || [];

  // baseBookings = baseBookings.filter(b => {
  //   if (selectedBookingServiceId === "ALL") return true;

  //   return b.serviceId === selectedBookingServiceId;
  // });

  const bookingsInProgress =
    baseBookings?.filter(b => {
      const statuses = [
        Booking_Status.Seated,
        Booking_Status.Arrived,
        Booking_Status.ArrivedWaiting,
      ];
      return statuses.includes(b.status);
    }) || [];

  const bookingsInProgressPersons = bookingsInProgress.reduce(
    (acc, b) => acc + b.nbPersons,
    0,
  );

  const otherBookings =
    baseBookings?.filter(b => {
      const statuses = [
        Booking_Status.New,
        Booking_Status.ModifiedByUser,
        Booking_Status.ModifiedByOwner,
        Booking_Status.Validated,
        Booking_Status.AutomaticValidated,
        Booking_Status.Delayed,
      ];
      return statuses.includes(b.status);
    }) || [];

  const otherBookingsPersons = otherBookings.reduce((acc, b) => acc + b.nbPersons, 0);

  return (
    <ContentModal
      title={displayTitle()}
      isVisible={isOpen}
      onClose={handleClose}
      styles={{
        paddingHorizontal: 0,
        maxWidth: 450,
        height: "auto",
      }}
      headerStyles={{
        paddingHorizontal: 8,
      }}
    >
      <Box minHeight="100%">
        <Box backgroundColor="disabled" p="s">
          <CustomText variant="label" color="primaryTextColor">
            Réservations assignées à cette table
          </CustomText>
        </Box>

        <Box height="100%" minHeight={50} marginVertical="m">
          {bookingsInProgress.length > 0 && (
            <Box>
              <FlatList
                style={{
                  overflow: "scroll",
                }}
                data={bookingsInProgress}
                keyExtractor={item => item._id}
                ListHeaderComponent={
                  <Box
                    pl="m"
                    flexDirection="row"
                    alignItems="center"
                    p="s"
                    borderBottomColor="lightGrey"
                    borderBottomWidth={1}
                  >
                    <CustomText
                      variant="label"
                      fontSize={14}
                      textTransform="uppercase"
                      color="primaryTextColor"
                    >
                      En cours
                    </CustomText>

                    <Box
                      flex={1}
                      height={1}
                      backgroundColor="lightGrey"
                      marginHorizontal="m"
                    />

                    <Box flexDirection="row" alignItems="center">
                      <Box flexDirection="row" alignItems="center">
                        <CALENDAR width={16} height={16} fill={PALETTE.lightGrey} />
                        <CustomText ml="s" variant="content" color="primaryTextColor">
                          {bookingsInProgress.length}
                        </CustomText>
                      </Box>
                      <Box ml="s" flexDirection="row" alignItems="center">
                        <CUTLERY width={16} height={16} fill={PALETTE.lightGrey} />
                        <CustomText ml="s" variant="content" color="primaryTextColor">
                          {bookingsInProgressPersons}
                        </CustomText>
                      </Box>
                    </Box>
                  </Box>
                }
                renderItem={({ item }) => (
                  <Box
                    p="s"
                    borderBottomColor="lightGrey"
                    borderBottomWidth={1}
                    minHeight={{
                      largeTablet: 100,
                    }}
                  >
                    <BookingListCard
                      item={item}
                      nameFormat={Module_Listing_Client_Format.LastFirstName}
                      isTablePressDisabled
                      isDisabled
                      onExtraActionPress={() => {}}
                      onTablePress={() => {}}
                      onPress={() => {}}
                      onCompleteHandler={handleRefreshTable}
                      showTables={false}
                      dateFormat="dd/MM - HH:mm"
                    />
                  </Box>
                )}
              />
            </Box>
          )}
          {otherBookings.length > 0 && (
            <Box mt="s">
              <FlatList
                style={{
                  overflow: "scroll",
                }}
                data={otherBookings}
                keyExtractor={item => item._id}
                ListHeaderComponent={
                  <Box
                    pl="m"
                    flexDirection="row"
                    alignItems="center"
                    p="s"
                    borderBottomColor="lightGrey"
                    borderBottomWidth={1}
                  >
                    <CustomText
                      variant="label"
                      fontSize={14}
                      textTransform="uppercase"
                      color="primaryTextColor"
                    >
                      A suivre
                    </CustomText>

                    <Box
                      flex={1}
                      height={1}
                      backgroundColor="lightGrey"
                      marginHorizontal="m"
                    />

                    <Box flexDirection="row" alignItems="center">
                      <Box flexDirection="row" alignItems="center">
                        <CALENDAR width={16} height={16} fill={PALETTE.lightGrey} />
                        <CustomText ml="s" variant="content" color="primaryTextColor">
                          {otherBookings.length}
                        </CustomText>
                      </Box>
                      <Box ml="s" flexDirection="row" alignItems="center">
                        <CUTLERY width={16} height={16} fill={PALETTE.lightGrey} />
                        <CustomText ml="s" variant="content" color="primaryTextColor">
                          {otherBookingsPersons}
                        </CustomText>
                      </Box>
                    </Box>
                  </Box>
                }
                renderItem={({ item }) => (
                  <Box
                    p="s"
                    borderBottomColor="lightGrey"
                    borderBottomWidth={1}
                    minHeight={{
                      largeTablet: 100,
                    }}
                  >
                    <BookingListCard
                      item={item}
                      nameFormat={Module_Listing_Client_Format.LastFirstName}
                      isDisabled
                      isTablePressDisabled
                      onExtraActionPress={() => {}}
                      onTablePress={() => {}}
                      onPress={() => {}}
                      onCompleteHandler={handleRefreshTable}
                      showTables={false}
                      dateFormat="dd/MM - HH:mm"
                    />
                  </Box>
                )}
              />
            </Box>
          )}

          {/* <Box>
            <FlatList
              data={table?.bookings || []}
              keyExtractor={item => item._id}
              renderItem={({ item }) => (
                <Box
                  paddingHorizontal="s"
                  borderBottomColor="lightGrey"
                  borderBottomWidth={1}
                >
                  <BookingListCard
                    item={item}
                    nameFormat={Module_Listing_Client_Format.LastFirstName}
                    isDisabled
                    isTablePressDisabled
                    onExtraActionPress={() => {}}
                    onTablePress={() => {}}
                    onPress={() => {}}
                  />
                </Box>
              )}
            />
          </Box> */}
        </Box>
        <Box
          position={"absolute"}
          left={0}
          bottom={0}
          right={0}
          width="100%"
          backgroundColor="white"
          gap="m"
          p="s"
        >
          <CustomButton
            buttonVariant="primaryButton"
            buttonColor="success"
            onPress={handleCreateBooking}
            styles={{ minHeight: 45 }}
          >
            <CustomText variant="primaryButtonText" color="white">
              Créer une réservation
            </CustomText>
          </CustomButton>
          {isCurrentDaySameAsSelectedDay && (
            <CustomButton
              buttonVariant="outlineButton"
              borderColor="success"
              onPress={handleNewLocalBooking}
              styles={{ minHeight: 45 }}
            >
              <CustomText variant="primaryButtonText" color="success">
                Créer une réservation de passage
              </CustomText>
            </CustomButton>
          )}
        </Box>
        {/* <BottomButton
          title="Créer une réservation"
          onPress={handleCreateBooking}
          styles={{
            bottom: IS_WEB ? 0 : 80,
          }}
        /> */}
      </Box>
    </ContentModal>
  );
};

export default BookingFloorTableViewModal;
