import { createContext } from "react";

import type { ConnectedUserFragment, Modules } from "../../graphql/generated/schema";

export interface AppContextInterface {
  licenceKey: string;
  apiToken: string;
  deviceToken: string;
  isOwner: boolean;
  staffId: string;
  merchantId: string;
  connectedUser: ConnectedUserFragment | null;
  merchantPermissions: Modules[];

  totalPendingBookings: number;
  totalPendingOrders: number;
  totalAlerts: number;

  hideHeaders: boolean;

  isSplittable: boolean;

  refreshAlerts: () => void;
  refreshMerchantPermissions: () => void;
  refreshApp: () => void;

  setHideHeaders: (hideHeaders: boolean) => void;

  loginUser: (api: string, key: string, staffId: string) => void;
  logOutUser: () => void;
}

export const AppContext = createContext<AppContextInterface>({} as AppContextInterface);
