/* eslint-disable @typescript-eslint/no-empty-function */

import { format } from "date-fns";
import { TouchableOpacity } from "react-native-gesture-handler";

import AGENDA_ADD from "../../../../../../../assets/icons/BASE/AGENDA_ADD.svg";
import AGENDA_EDIT from "../../../../../../../assets/icons/BASE/AGENDA_EDIT.svg";
import CASH from "../../../../../../../assets/icons/BASE/CASH.svg";
import COMMENT from "../../../../../../../assets/icons/BASE/COMMENT.svg";
import COMMENT_OWNER from "../../../../../../../assets/icons/BASE/COMMENT_OWNER.svg";
import CREDIT_CARD from "../../../../../../../assets/icons/BASE/CREDIT_CARD.svg";
import EMAIL from "../../../../../../../assets/icons/BASE/EMAIL.svg";
import EVENT from "../../../../../../../assets/icons/BASE/EVENT.svg";
import INFO from "../../../../../../../assets/icons/BASE/INFO.svg";
import INTERNET from "../../../../../../../assets/icons/BASE/INTERNET.svg";
import MESSAGE from "../../../../../../../assets/icons/BASE/MESSAGE.svg";
import PHONE from "../../../../../../../assets/icons/BASE/PHONE.svg";
import PHONE_OTP from "../../../../../../../assets/icons/BASE/PHONE_OTP.svg";
import TABLE from "../../../../../../../assets/icons/BASE/TABLE.svg";
import type { BookingFragment } from "../../../../../../graphql/generated/schema";
import {
  Booking_Status,
  Payment_Card_Status,
  Payment_Type,
  Resource_Request_Type,
} from "../../../../../../graphql/generated/schema";
import type { THEME_COLORS } from "../../../../../../theme";
import { LINE_THICKNESS } from "../../../../../../theme";
import { PALETTE } from "../../../../../../theme/Palette";
import { handleEmailLinking, handlePhoneLinking } from "../../../../../../utils/Linking";
import { getLabelForBookingStatus } from "../../../../../../utils/booking";
import {
  formatCurrencyPrice,
  getLabelsForEvent,
  getLabelsForOrigin,
} from "../../../../../../utils/common";
import Box from "../../../../../Base/Box";
import { CustomText } from "../../../../../Base/Text";
import BookingListCardStatusButton from "../../../../../BookingList/BookingListCardStatusButton";
import { BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE } from "../../../../../BookingList/BookingListCardStatusButtonItem";
import DisplayOrderItems from "../../../../../OnlinOrdersListDetails/DisplayOrderItems";
import TabTextInfo from "../../TabTextInfo";

interface BookingListDetailsViewProps {
  data: BookingFragment;
}

const ICON_SIZE = 18;

const BookingListDetailsView = ({ data }: BookingListDetailsViewProps) => {
  const {
    merchantClient: client,
    commentOwner,
    comment,
    source,
    eventType,
    status,
    items,
    payment,
    prepaidPayment,
    createdAt,
    updatedAt,
    refusalReason,
    prepaidPaymentRequested,
    otpRequest,
    otp,
    floor,
    tables,
    allergies,
  } = data;
  // const { phone, email } = client;

  const formattedUpdatedDate = format(new Date(updatedAt), "dd/MM/yyyy HH:mm");
  const formattedCreatedDate = format(new Date(createdAt), "dd/MM/yyyy HH:mm");
  const HAS_PRODUCT_ITEMS = items.menus.length > 0 || items.products.length > 0;
  const shouldShowFloor = floor && tables?.length === 0;

  const determinePaymentColor = (): THEME_COLORS => {
    const IS_CANCELLED =
      status === Booking_Status.Noshow ||
      status === Booking_Status.CancelledByOwner ||
      status === Booking_Status.CancelledByUser;

    const IS_REFUNDED = status === Booking_Status.Refunded;

    if (IS_CANCELLED || IS_REFUNDED) return "danger";

    return payment?.paid ? "success" : "orange";
  };

  const determinePaymentType = (): string => {
    if (!payment) return "";
    if (payment.paymentType === Payment_Type.Card) {
      return "Carte bancaire";
    } else if (payment?.paymentType === Payment_Type.Cash) {
      return "Espèces";
    }
    return "Espèces";
  };

  const determinePaymentStatus = (): string => {
    if (!payment) return "";

    if (payment.paymentType === Payment_Type.Card) {
      if (payment.paid) return "Payé";

      return "En att. de validation";
    } else if (
      payment?.paymentType === Payment_Type.Cash ||
      payment?.paymentType === Payment_Type.None
    ) {
      if (payment?.paid) return "Payé";

      return "A encaisser";
    }
    return "-";
  };

  const displayPhoneButton = () => {
    const handleOpenPhone = async () => {
      try {
        if (client?.phone) await handlePhoneLinking(client?.phone);
      } catch (err) {
        console.log("err", err);
      }
    };
    if (client?.phone) {
      return (
        <TouchableOpacity onPress={handleOpenPhone}>
          <Box
            backgroundColor="white"
            borderColor="primaryTextColor"
            borderWidth={LINE_THICKNESS}
            p="s"
            alignItems="center"
            justifyContent="center"
            style={{ borderRadius: 25 }}
          >
            <PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
          </Box>
        </TouchableOpacity>
      );
    }
  };

  const displayEmailButton = () => {
    const handleOpenEmail = async () => {
      try {
        if (client?.email) await handleEmailLinking(client?.email);
      } catch (err) {
        console.log("err", err);
      }
    };
    if (client?.email) {
      return (
        <TouchableOpacity onPress={handleOpenEmail}>
          <Box
            backgroundColor="white"
            borderColor="primaryTextColor"
            borderWidth={LINE_THICKNESS}
            p="s"
            alignItems="center"
            justifyContent="center"
            style={{ borderRadius: 25 }}
          >
            <EMAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.purple} />
          </Box>
        </TouchableOpacity>
      );
    }
  };

  return (
    <Box flex={1}>
      <TabTextInfo
        {...{
          title: "Statut de la réservation",
          content: getLabelForBookingStatus(status),
          icon: <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          rightIcon: (
            <Box>
              <BookingListCardStatusButton
                type={BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.ICON}
                status={status}
                isDisabled
                onPress={() => {}}
              />
            </Box>
          ),
        }}
      />

      {(status === Booking_Status.CancelledByUser ||
        status === Booking_Status.CancelledByOwner) && (
        <TabTextInfo
          {...{
            title: "Raison de l'annulation",
            content: refusalReason,
            icon: (
              <MESSAGE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
      )}
      {comment && (
        <TabTextInfo
          {...{
            title: "Commentaire client",
            content: comment,
            icon: (
              <MESSAGE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
      )}

      {commentOwner && (
        <TabTextInfo
          {...{
            title: "Note de la réservation",
            content: commentOwner,
            icon: (
              <COMMENT_OWNER
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}
      {client?.comment && (
        <TabTextInfo
          {...{
            title: "Note permanante sur le client",
            content: client.comment,
            icon: (
              <COMMENT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
            ),
          }}
        />
      )}

      {allergies && (
        <TabTextInfo
          {...{
            title: "Allergie",
            content: allergies,
            icon: <INFO height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
      )}

      {HAS_PRODUCT_ITEMS && (
        <TabTextInfo
          {...{
            title: "Mode de paiement",
            content: determinePaymentType(),
            rightIcon: (
              <Box
                backgroundColor={determinePaymentColor()}
                borderRadius="icon"
                p="s"
                alignItems="center"
                justifyContent="center"
                minWidth={100}
              >
                <CustomText variant="description" color="white">
                  {determinePaymentStatus()}
                </CustomText>
              </Box>
            ),
            icon: <CASH height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.darkGrey} />,
          }}
        />
      )}

      {shouldShowFloor && (
        <TabTextInfo
          {...{
            title: "Salle sélectionnée",
            content: floor.floorName,
            icon: <TABLE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
          }}
        />
      )}

      {client?.phone && (
        <TabTextInfo
          {...{
            title: "Téléphone",
            content: client?.phone,
            icon: <PHONE height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
            rightIcon: displayPhoneButton(),
          }}
        />
      )}

      {client?.email && (
        <TabTextInfo
          {...{
            title: "Mail",
            content: client?.email,
            icon: <EMAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
            rightIcon: displayEmailButton(),
          }}
        />
      )}
      {prepaidPayment?.cardPaymentStatus === Payment_Card_Status.Refunded && (
        <TabTextInfo
          {...{
            title: "L'empreinte bancaire remboursée",
            content: "Oui",
            icon: (
              <CREDIT_CARD
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}
      {prepaidPayment?.cardPaymentStatus === Payment_Card_Status.Charged && (
        <TabTextInfo
          {...{
            title: "L'empreinte bancaire prélevée",
            content: "Oui",
            icon: (
              <CREDIT_CARD
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      {prepaidPayment?.cardPaymentStatus === Payment_Card_Status.Cancelled && (
        <TabTextInfo
          {...{
            title: "L'empreinte bancaire annulée/libérée",
            content: "Oui",
            icon: (
              <CREDIT_CARD
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      {prepaidPayment?.paid && (
        <TabTextInfo
          {...{
            title: "L'empreinte bancaire déposée",
            content: !!prepaidPayment?.paid ? "Oui" : "Non",
            icon: (
              <CREDIT_CARD
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      {prepaidPaymentRequested && (
        <TabTextInfo
          {...{
            title: "Demande d'empreinte bancaire envoyée",
            content: "Oui",
            icon: (
              <CREDIT_CARD
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      {prepaidPayment && (
        <TabTextInfo
          {...{
            title: "Empreinte bancaire",
            content: format(new Date(prepaidPayment.createdAt), "dd/MM/yyyy HH:mm"),
            icon: (
              <CREDIT_CARD
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      {prepaidPayment && (
        <TabTextInfo
          {...{
            title: "Montant de l'empreinte bancaire",
            content: formatCurrencyPrice(prepaidPayment.amount, "fr", "eur"),
            icon: (
              <CREDIT_CARD
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill={PALETTE.lightGrey}
              />
            ),
          }}
        />
      )}

      {otpRequest && (
        <TabTextInfo
          {...{
            title: "Demande de vérification téléphonique",
            content: "Oui",
            icon: <PHONE_OTP height={28} width={28} fill={PALETTE.lightGrey} />,
          }}
        />
      )}
      {otpRequest?.requestedAt && (
        <TabTextInfo
          {...{
            title: "Demande de vérification téléphonique envoyée à",
            content: format(new Date(otpRequest.requestedAt), "dd/MM/yyyy HH:mm"),
            icon: <PHONE_OTP height={28} width={28} fill={PALETTE.lightGrey} />,
          }}
        />
      )}
      {otpRequest?.requestSentType && (
        <TabTextInfo
          {...{
            title: "Demande de vérification téléphonique envoyée par",
            content:
              otpRequest.requestSentType === Resource_Request_Type.Email ? "Mail" : "SMS",
            icon: <INFO height={28} width={28} fill={PALETTE.lightGrey} />,
          }}
        />
      )}
      {otp && (
        <TabTextInfo
          {...{
            title: "Code de vérification",
            content: otp.code,
            icon: <PHONE_OTP height={28} width={28} fill={PALETTE.lightGrey} />,
          }}
        />
      )}

      {otp && (
        <TabTextInfo
          {...{
            title: "Code envoyé à",
            content: format(new Date(otp.createdAt), "dd/MM/yyyy HH:mm"),
            icon: <PHONE_OTP height={28} width={28} fill={PALETTE.lightGrey} />,
          }}
        />
      )}
      {/* {otp && (
        <TabTextInfo
          {...{
            title: "Code valide jusqu'à",
            content: format(new Date(otp.expiresAt), "dd/MM/yyyy HH:mm"),
            icon: <PHONE_OTP height={28} width={28} fill={PALETTE.lightGrey} />,
          }}
        />
      )} */}
      {otp?.verified && (
        <TabTextInfo
          {...{
            title: "Code utilisé",
            content: `Oui - ${format(new Date(otp.verifiedAt), "dd/MM/yyyy HH:mm")}`,
            icon: <PHONE_OTP height={28} width={28} fill={PALETTE.green} />,
          }}
        />
      )}

      <TabTextInfo
        {...{
          title: "Évènement",
          content: eventType ? getLabelsForEvent(eventType, eventType) : "-",
          icon: <EVENT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />,
        }}
      />

      <TabTextInfo
        {...{
          title: "Source",
          content: getLabelsForOrigin(source, source),
          icon: (
            <INTERNET height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
          ),
        }}
      />

      {HAS_PRODUCT_ITEMS && (
        <DisplayOrderItems title="Détails de la commande" items={items} />
      )}

      <TabTextInfo
        {...{
          title: "Date de modification",
          content: formattedUpdatedDate,
          icon: (
            <AGENDA_EDIT height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
          ),
        }}
      />
      <TabTextInfo
        {...{
          title: "Date de création",
          content: formattedCreatedDate,
          icon: (
            <AGENDA_ADD height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.lightGrey} />
          ),
        }}
      />
    </Box>
  );
};

export default BookingListDetailsView;
