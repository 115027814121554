import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { FlatList } from "react-native-gesture-handler";
import Box from "../../../components/Base/Box";
import { CustomText } from "../../../components/Base/Text";
import BottomButton from "../../../components/BottomButton";
import ScreenHeader from "../../../components/ScreenHeader";
import Touchable from "../../../components/Touchable";
import { client, setupApolloClientLink } from "../../../config/ApolloClient";
import { AppContext } from "../../../contexts/AppContext";
import {
  GetMerchantOrganisationQuery,
  useGetMerchantOrganisationLazyQuery,
  useSwitchMerchantContextInOrganisationMutation,
} from "../../../graphql/generated/schema";

interface MerchantOrganisationMerchantsProps {
  goBack?: () => void;
}

const MerchantOrganisationMerchants = ({
  goBack,
}: MerchantOrganisationMerchantsProps) => {
  const navigation = useNavigation();
  const appContext = useContext(AppContext);
  const [isSwitchingMerchant, setIsSwitchingMerchant] = useState(false);
  const [merchantToSwitchTo, setMerchantToSwitchTo] = useState(appContext.merchantId);
  const [switchMerchantContext] = useSwitchMerchantContextInOrganisationMutation();

  const [merchantOrganisation, setMerchantOrganisation] = useState<
    GetMerchantOrganisationQuery["getMerchantOrganisation"] | null
  >(null);

  const [getMerchantOrganisation] = useGetMerchantOrganisationLazyQuery();

  const handleGetMerchantOrganisation = async () => {
    try {
      const response = await getMerchantOrganisation();

      setMerchantOrganisation(response.data?.getMerchantOrganisation || null);
    } catch (err) {
      console.log("err get merchant organisation: ", err);
    }
  };

  useEffect(() => {
    handleGetMerchantOrganisation();
  }, []);

  const handleSwitchMerchant = async () => {
    try {
      if (merchantToSwitchTo === appContext.merchantId) return;
      if (!merchantToSwitchTo) return;

      setIsSwitchingMerchant(true);

      const response = await switchMerchantContext({
        variables: {
          merchantId: merchantToSwitchTo,
        },
      });

      const newMerchantToken = response.data?.switchMerchantContextInOrganisation?.token;

      if (!newMerchantToken) return;

      const newApolloClientLink = setupApolloClientLink(newMerchantToken);

      await client.resetStore();
      await client.cache.reset();

      client.setLink(newApolloClientLink);
      appContext.loginUser(newMerchantToken, "", "");

      navigation.navigate("DASHBOARD");
    } catch (err) {
      console.log("err switch merchant context: ", err);
    } finally {
      setIsSwitchingMerchant(false);
    }
  };

  if (!merchantOrganisation) return null;

  return (
    <Box paddingHorizontal="s" pt="m" flex={1} backgroundColor="white">
      <ScreenHeader
        title={merchantOrganisation?.label || "Organisation"}
        hasBackButton={!goBack}
        onBackPress={goBack || navigation.goBack}
      />
      <Box mt="m">
        <FlatList
          data={merchantOrganisation.merchants}
          keyExtractor={item => item._id}
          renderItem={({ item }) => {
            const isSelected = merchantToSwitchTo === item._id;

            return (
              <Touchable onPress={() => setMerchantToSwitchTo(item._id)}>
                <Box
                  p="m"
                  mb="s"
                  minHeight={45}
                  backgroundColor="disabled"
                  borderRadius="button"
                  borderColor={isSelected ? "success" : "disabled"}
                  borderWidth={1}
                  justifyContent="center"
                >
                  <CustomText variant="label" color="primaryTextColor">
                    {item.label}
                  </CustomText>
                  <CustomText
                    variant="paragraph"
                    color="lightGrey"
                    ellipsizeMode="tail"
                    numberOfLines={2}
                  >
                    {item.description}
                  </CustomText>
                </Box>
              </Touchable>
            );
          }}
        />
      </Box>
      <BottomButton
        isLoading={isSwitchingMerchant}
        isDisabled={merchantToSwitchTo === appContext.merchantId || isSwitchingMerchant}
        title="Changer d'établissement"
        onPress={handleSwitchMerchant}
      />
    </Box>
  );
};

export default MerchantOrganisationMerchants;
