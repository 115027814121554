/* eslint-disable max-len */
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import AddressDetails from "../../../components/AddressDetails";
import ListMultiSelect from "../../../components/ListMultiSelect";
import Settings from "../../../screens/Settings";
import AccountAndSubscriptions from "../../../screens/Settings/AccountAndSubscriptions";
import AccountBillingDetails from "../../../screens/Settings/AccountAndSubscriptions/Account/AccountBillingDetails";
import AddPaymentMethod from "../../../screens/Settings/AccountAndSubscriptions/Account/AccountBillingDetails/AddPaymentMethod";
import BillingDetails from "../../../screens/Settings/AccountAndSubscriptions/Account/AccountBillingDetails/BillingDetails";
import PaymentMethodDetails from "../../../screens/Settings/AccountAndSubscriptions/Account/AccountBillingDetails/PaymentMethodDetails";
import AccountDetails from "../../../screens/Settings/AccountAndSubscriptions/Account/AccountDetails";
import AccountSecurity from "../../../screens/Settings/AccountAndSubscriptions/Account/AccountSecurity";
import AddSubscription from "../../../screens/Settings/AccountAndSubscriptions/Subscriptions/AddSubscription";
import InvoicesList from "../../../screens/Settings/AccountAndSubscriptions/Subscriptions/InvoicesList";
import SubscriptionDetails from "../../../screens/Settings/AccountAndSubscriptions/Subscriptions/SubscriptionDetails";
import AccountSettingsList from "../../../screens/Settings/Accounting/AccountSettingList";
import AccountingReports from "../../../screens/Settings/Accounting/AccountingReports";
import CashRegisterSettingsPaymentMethods from "../../../screens/Settings/Accounting/CashRegisterSettingsPaymentMethods";
import MerchantDiscountList from "../../../screens/Settings/Accounting/MerchantDiscount/MerchantDiscountList";
import MerchantDiscountListDetails from "../../../screens/Settings/Accounting/MerchantDiscount/MerchantDiscountListDetails";
import PricingRatesList from "../../../screens/Settings/Accounting/PricingRates/PricingRatesList";
import PricingRatesListDetails from "../../../screens/Settings/Accounting/PricingRates/PricingRatesListDetails";
import TaxList from "../../../screens/Settings/Accounting/Tax/TaxList";
import TaxListDetails from "../../../screens/Settings/Accounting/Tax/TaxListDetails";
import BookingClientSettings from "../../../screens/Settings/Booking/BookingClientSettings/index";
import BookingClosureDetails from "../../../screens/Settings/Booking/BookingClosureDetails";
import BookingClosuresSettings from "../../../screens/Settings/Booking/BookingClosuresSettings";
import BookingFloorSettings from "../../../screens/Settings/Booking/BookingFloorSettings";
import BookingFormSettings from "../../../screens/Settings/Booking/BookingForm";
import BookingGeneralSettings from "../../../screens/Settings/Booking/BookingGeneralSettings";
import BookingListSettings from "../../../screens/Settings/Booking/BookingListSettings";
import BookingPdfPrintTemplatesSettingsDetails from "../../../screens/Settings/Booking/BookingPdfPrintTemplatesSettings/BookingPdfPrintTemplatesSettingsDetails";
import BookingPdfPrintTemplatesSettings from "../../../screens/Settings/Booking/BookingPdfPrintTemplatesSettings/index";
import BookingServiceFloorSelect from "../../../screens/Settings/Booking/BookingServicesSettings/BookingServiceFloorSelect";
import BookingServiceList from "../../../screens/Settings/Booking/BookingServicesSettings/BookingServiceList";
import BookingServiceListDetails from "../../../screens/Settings/Booking/BookingServicesSettings/BookingServiceListDetails";
import BookingServiceProductSelect from "../../../screens/Settings/Booking/BookingServicesSettings/BookingServiceProductSelect";
import BookingServiceProductSelectList from "../../../screens/Settings/Booking/BookingServicesSettings/BookingServiceProductSelectList";
import BookingServiceSlotListDetails from "../../../screens/Settings/Booking/BookingServicesSettings/BookingServiceSlotListDetails";
import BookingServiceSlotsList from "../../../screens/Settings/Booking/BookingServicesSettings/BookingServiceSlotsList";
import BookingSettingsList from "../../../screens/Settings/Booking/BookingSettingsList";
import BookingFloorGeneralSettings from "../../../screens/Settings/BookingFloorGeneralSettings";
import BookingFloor from "../../../screens/Settings/BookingFloorGeneralSettings/BookingFloor";
import BookingsFloors from "../../../screens/Settings/BookingFloorGeneralSettings/BookingFloors";
import CashRegisterSettings from "../../../screens/Settings/CashRegisterSettings";
import ClientSettings from "../../../screens/Settings/Clients";
import ClientList from "../../../screens/Settings/Clients/ClientList";
import ClientListDetails from "../../../screens/Settings/Clients/ClientListDetails";
import CompanyList from "../../../screens/Settings/Clients/CompanyList";
import CompanyListDetails from "../../../screens/Settings/Clients/CompanyListDetails";
import PrescriberList from "../../../screens/Settings/Clients/PrescriberList";
import PrescriberListDetails from "../../../screens/Settings/Clients/PrescriberListDetails";
import PrescriberListDetailsAddPrincipalContact from "../../../screens/Settings/Clients/PrescriberListDetailsAddPrincipalContact";
import DashbaordSettings from "../../../screens/Settings/Dashboard";
import DataSettingsList from "../../../screens/Settings/Data";
import CategoryAddProduct from "../../../screens/Settings/Data/Categories/CategoryAddProduct";
import CategoryList from "../../../screens/Settings/Data/Categories/CategoryList";
import CategoryListDetails from "../../../screens/Settings/Data/Categories/CategoryListDetails";
import CombinedCategoryAddCategory from "../../../screens/Settings/Data/CombinedCategories/CombinedCategoryAddCategory";
import CombinedCategoryList from "../../../screens/Settings/Data/CombinedCategories/CombinedCategoryList";
import CombinedCategoryListDetails from "../../../screens/Settings/Data/CombinedCategories/CombinedCategoryListDetails";
import GroupOptionAddOption from "../../../screens/Settings/Data/GroupOptions/GroupOptionAddOption";
import GroupOptionsListDetails from "../../../screens/Settings/Data/GroupOptions/GroupOptionListDetails";
import GroupOptionsList from "../../../screens/Settings/Data/GroupOptions/GroupOptionsList";
import MenuList from "../../../screens/Settings/Data/Menus/MenuList";
import MenuListDetails from "../../../screens/Settings/Data/Menus/MenuListDetails";
import MenuStep from "../../../screens/Settings/Data/Menus/MenuListDetails/MenuStep";
import MenuStepAddProduct from "../../../screens/Settings/Data/Menus/MenuListDetails/MenuStepAddProduct";
import OptionsList from "../../../screens/Settings/Data/Options/OptionsList";
import OptionsListDetails from "../../../screens/Settings/Data/Options/OptionsListDetails";
import ProductAddCategoryGroupOption from "../../../screens/Settings/Data/Products/ProductAddCategoryGroupOption";
import ProductList from "../../../screens/Settings/Data/Products/ProductList";
import ProductListDetails from "../../../screens/Settings/Data/Products/ProductListDetails";
import ProductsWithStockEnabledList from "../../../screens/Settings/Data/ProductsWithStockEnabledList/index";
import StockList from "../../../screens/Settings/Data/StockList";
import DevicesSettingsList from "../../../screens/Settings/Devices";
import AddPrinter from "../../../screens/Settings/Devices/AddPrinter";
import MerchantDevicesList from "../../../screens/Settings/Devices/MerchantDevicesList";
import PrinterErrorReports from "../../../screens/Settings/Devices/PrinterErrorReports/index";
import PrinterList from "../../../screens/Settings/Devices/PrintersList";
import DigitalMenuList from "../../../screens/Settings/DigitalMenu/DigitalMenuList";
import DigitalMenuListDetails from "../../../screens/Settings/DigitalMenu/DigitalMenuListDetails/index";
import Characteristics from "../../../screens/Settings/Establishment/Characteristics";
import EstablishmentSettingsList from "../../../screens/Settings/Establishment/EstablishmentSettingsList";
import HoursAndLocations from "../../../screens/Settings/Establishment/HoursAndLocations";
import MerchantChef from "../../../screens/Settings/Establishment/MerchantChef";
import MerchantInfo from "../../../screens/Settings/Establishment/MerchantInfo";
import MerchantPhotos from "../../../screens/Settings/Establishment/MerchantPhotos";
import GeneralSettings from "../../../screens/Settings/General";
import Help from "../../../screens/Settings/Help";
import ClientNotifications from "../../../screens/Settings/Notifications/ClientNotifications";
import DeviceNotifications from "../../../screens/Settings/Notifications/DeviceNotifications";
import NotificationsSettingsList from "../../../screens/Settings/Notifications/NotificationsSettingsList";
import ProNotifications from "../../../screens/Settings/Notifications/ProNotifications";
import DeliveryClosuresSettings from "../../../screens/Settings/OnlineSales/Delivery/DeliveryClosuresSettings";
import DeliveryFormSettings from "../../../screens/Settings/OnlineSales/Delivery/DeliveryFormSettings";
import DeliveryGeneralSettings from "../../../screens/Settings/OnlineSales/Delivery/DeliveryGeneralSettings";
import DeliveryHoursSettings from "../../../screens/Settings/OnlineSales/Delivery/DeliveryHoursSettings";
import DeliveryListSettings from "../../../screens/Settings/OnlineSales/Delivery/DeliveryListSettings";
import OnlineSalesSettingsList from "../../../screens/Settings/OnlineSales/OnlineSalesSettingsList";
import TakeAwayClientSettings from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayClientSettings/index";
import TakeAwayClosureDetails from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayClosureDetails";
import TakeAwayClosuresSettings from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayClosuresSettings";
import TakeAwayFormSettings from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayFormSettings";
import TakeAwayGeneralSettings from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayGeneralSettings";
import TakeAwayListSettings from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayListSettings";
import TakeAwayServiceList from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayServiceList";
import TakeAwayServiceListDetails from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayServiceListDetails";
import TakeAwayServiceSlotsList from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayServiceSlotsList";
import TakeAwayServiceSlotsListDetails from "../../../screens/Settings/OnlineSales/TakeAway/TakeAwayServiceSlotsListDetails";
import Partners from "../../../screens/Settings/Partners";
import PerformanceAndStatSettings from "../../../screens/Settings/PerformanceSettings";
import PerformanceSettings from "../../../screens/Settings/PerformanceSettings/ReportSettings/index";
import StatGoalsSettings from "../../../screens/Settings/PerformanceSettings/StatGoals/index";
import QuoteInvoiceSettings from "../../../screens/Settings/QuoteInvoiceSettings";
import InvoiceSettings from "../../../screens/Settings/QuoteInvoiceSettings/InvoiceSettings";
import QuoteSettings from "../../../screens/Settings/QuoteInvoiceSettings/QuoteSettings";
import ReviewResponsesList from "../../../screens/Settings/Reviews/ReviewResponsesList";
import ReviewResponsesListDetails from "../../../screens/Settings/Reviews/ReviewResponsesListDetails";
import AuthentificationMethod from "../../../screens/Settings/Staff/AuthentificationMethod";
import PermissionsGroupList from "../../../screens/Settings/Staff/PermissionsGroupList";
import PermissionsGroupListDetails from "../../../screens/Settings/Staff/PermissionsGroupListDetails";
import StaffList from "../../../screens/Settings/Staff/StaffList";
import StaffListDetails from "../../../screens/Settings/Staff/StaffListDetails";
import StaffSettingsList from "../../../screens/Settings/Staff/StaffSettingsList";
import TablePayment from "../../../screens/Settings/TablePaymentSettings";

import MerchantOrganisationMerchants from "../../../screens/Settings/MerchantOrganisationMerchants";
import type { SettingsStackParamList } from "./ParamList";
const Stack = createNativeStackNavigator<SettingsStackParamList>();

const SettingsStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: () => null,
      }}
      initialRouteName="SETTINGS_LIST"
    >
      {/* <Stack.Group screenOptions={{ presentation: "modal" }}> */}
      <Stack.Screen name="LIST_SELECT" component={ListMultiSelect} />
      {/* </Stack.Group> */}

      <Stack.Screen name="SETTINGS_LIST" component={Settings} />
      <Stack.Screen name="GENERAL_SETTINGS_LIST" component={GeneralSettings} />
      <Stack.Screen name="DASHBOARD_SETTINGS" component={DashbaordSettings} />
      <Stack.Screen name="CLIENT_SETTINGS_LIST" component={ClientSettings} />

      <Stack.Screen name="COMPANY_LIST" component={CompanyList} />
      <Stack.Screen name="COMPANY_LIST_DETAILS" component={CompanyListDetails} />

      <Stack.Screen name="CLIENT_LIST" component={ClientList} />
      <Stack.Screen name="CLIENT_LIST_DETAILS" component={ClientListDetails} />

      <Stack.Screen name="PRESCRIBER_LIST" component={PrescriberList} />
      <Stack.Screen name="PRESCRIBER_LIST_DETAILS" component={PrescriberListDetails} />
      <Stack.Screen
        name="PRESCRIBER_LIST_DETAILS_ADD_PRINCIPAL_CONTACT"
        component={PrescriberListDetailsAddPrincipalContact}
      />

      <Stack.Screen name="STAFF_SETTINGS_LIST" component={StaffSettingsList} />

      <Stack.Screen name="STAFF_METHOD_CONNECTION" component={AuthentificationMethod} />
      <Stack.Screen name="STAFF_LIST" component={StaffList} />
      <Stack.Screen name="STAFF_LIST_DETAILS" component={StaffListDetails} />
      <Stack.Screen name="PERMISSIONS_GROUPS_LIST" component={PermissionsGroupList} />
      <Stack.Screen
        name="PERMISSIONS_GROUPS_LIST_DETAILS"
        component={PermissionsGroupListDetails}
      />
      <Stack.Screen name="DEVICES_SETTINGS_LIST" component={DevicesSettingsList} />
      <Stack.Screen name="DEVICES_LIST" component={MerchantDevicesList} />
      <Stack.Screen name="PRINTERS_LIST" component={PrinterList} />
      <Stack.Screen name="ADD_PRINTER" component={AddPrinter} />
      <Stack.Screen name="PRINTER_ERROR_REPORTS" component={PrinterErrorReports} />
      {/* <Stack.Screen name="PRODUCTS_SETTINGS_LIST" component={ProductsSettingsList} /> */}
      {/* <Stack.Screen name="PRODUCT_LIST" component={ProductsList} /> */}
      <Stack.Screen name="REVIEW_RESPONSES_LIST" component={ReviewResponsesList} />
      <Stack.Screen
        name="REVIEW_RESPONSES_LIST_DETAILS"
        component={ReviewResponsesListDetails}
      />

      <Stack.Screen
        name="NOTIFICATIONS_SETTINGS_LIST"
        component={NotificationsSettingsList}
      />
      <Stack.Screen name="NOTIFICATIONS_PRO_LIST" component={ProNotifications} />
      <Stack.Screen name="NOTIFICATIONS_DEVICE_LIST" component={DeviceNotifications} />
      <Stack.Screen name="NOTIFICATIONS_CLIENT_LIST" component={ClientNotifications} />
      <Stack.Screen
        name="ESTABLISHMENT_SETTINGS_LIST"
        component={EstablishmentSettingsList}
      />
      <Stack.Screen name="MERCHANT_INFO" component={MerchantInfo} />
      <Stack.Screen name="MERCHANT_CHEF" component={MerchantChef} />
      <Stack.Screen name="MERCHANT_CHARACTERISTICS" component={Characteristics} />
      <Stack.Screen name="MERCHANT_PHOTOS" component={MerchantPhotos} />
      <Stack.Screen name="MERCHANT_HOURS" component={HoursAndLocations} />

      {/* START BOOKING FLOOR */}
      <Stack.Screen
        name="BOOKING_FLOOR_GENERAL_SETTINGS"
        component={BookingFloorGeneralSettings}
      />
      <Stack.Screen name="BOOKING_FLOORS_LIST" component={BookingsFloors} />
      <Stack.Screen name="BOOKING_FLOOR" component={BookingFloor} />

      {/* END BOOKING FLOOR */}

      <Stack.Screen name="BOOKING_FLOOR_SETTINGS" component={BookingFloorSettings} />
      <Stack.Screen name="BOOKING_SETTINGS_LIST" component={BookingSettingsList} />
      <Stack.Screen name="BOOKING_GENERAL_SETTINGS" component={BookingGeneralSettings} />
      <Stack.Screen name="BOOKING_CLIENT_SETTINGS" component={BookingClientSettings} />
      <Stack.Screen name="BOOKING_FORM_SETTINGS" component={BookingFormSettings} />
      <Stack.Screen name="BOOKING_LIST_SETTINGS" component={BookingListSettings} />
      <Stack.Screen name="BOOKING_SERVICES_LIST" component={BookingServiceList} />
      <Stack.Screen
        name="BOOKING_SERVICES_LIST_DETAILS"
        component={BookingServiceListDetails}
      />
      <Stack.Screen
        name="BOOKING_SERVICES_SLOTS_LIST"
        component={BookingServiceSlotsList}
      />
      <Stack.Screen
        name="BOOKING_SERVICES_SLOTS_LIST_DETAILS"
        component={BookingServiceSlotListDetails}
      />
      <Stack.Screen
        name="BOOKING_SERVICES_FLOOR_SELECT"
        component={BookingServiceFloorSelect}
      />
      <Stack.Screen
        name="BOOKING_SERVICES_PRODUCT_SELECT"
        component={BookingServiceProductSelect}
      />
      <Stack.Screen
        name="BOOKING_SERVICES_PRODUCT_SELECT_LIST"
        component={BookingServiceProductSelectList}
      />
      <Stack.Screen
        name="BOOKING_CLOSURES_SETTINGS"
        component={BookingClosuresSettings}
      />
      <Stack.Screen name="BOOKING_CLOSURES_DETAILS" component={BookingClosureDetails} />
      <Stack.Screen
        name="BOOKING_PDF_SETTINGS"
        component={BookingPdfPrintTemplatesSettings}
      />
      <Stack.Screen
        name="BOOKING_PDF_TEMPLATE_DETAILS"
        component={BookingPdfPrintTemplatesSettingsDetails}
      />

      {/* START PARNTERS SETTINGS */}
      <Stack.Screen name="PARTNERS" component={Partners} />
      {/* END PARNTERS SETTINGS */}

      {/* START CASH_REGISTER_SETTINGS  */}
      <Stack.Screen name="CASH_REGISTER_SETTINGS" component={CashRegisterSettings} />
      {/* END CASH_REGISTER_SETTINGS  */}

      {/* START TABLE_PAYMENT_SETTINGS  */}
      <Stack.Screen name="TABLE_PAYMENT_SETTINGS" component={TablePayment} />
      {/* END TABLE_PAYMENT_SETTINGS  */}

      {/* START PERFORMANCE_SETTINGS  */}
      <Stack.Screen
        name="PERFORMANCE_AND_STAT_SETTINGS"
        component={PerformanceAndStatSettings}
      />
      <Stack.Screen name="PERFORMANCE_SETTINGS" component={PerformanceSettings} />
      <Stack.Screen name="STAT_SETTINGS" component={StatGoalsSettings} />
      {/* END PERFORMANCE_SETTINGS  */}

      {/* START ONLINE SALES SETTINGS */}
      <Stack.Screen
        name="ONLINE_SALES_SETTINGS_LIST"
        component={OnlineSalesSettingsList}
      />
      {/* END ONLINE SALES SETTINGS */}

      {/* START TAKE AWAY SETTINGS */}
      <Stack.Screen
        name="TAKE_AWAY_GENERAL_SETTINGS"
        component={TakeAwayGeneralSettings}
      />
      <Stack.Screen name="TAKE_AWAY_CLIENT_SETTINGS" component={TakeAwayClientSettings} />
      <Stack.Screen name="TAKE_AWAY_SERVICE_LIST" component={TakeAwayServiceList} />
      <Stack.Screen
        name="TAKE_AWAY_SERVICE_LIST_DETAILS"
        component={TakeAwayServiceListDetails}
      />
      <Stack.Screen
        name="TAKE_AWAY_SERVICE_SLOTS_LIST"
        component={TakeAwayServiceSlotsList}
      />
      <Stack.Screen
        name="TAKE_AWAY_SERVICE_SLOTS_LIST_DETAILS"
        component={TakeAwayServiceSlotsListDetails}
      />

      <Stack.Screen
        name="TAKE_AWAY_CLOSURES_SETTINGS"
        component={TakeAwayClosuresSettings}
      />
      <Stack.Screen
        name="TAKE_AWAY_CLOSURES_DETAILS"
        component={TakeAwayClosureDetails}
      />
      <Stack.Screen name="TAKE_AWAY_FORM_SETTINGS" component={TakeAwayFormSettings} />
      <Stack.Screen name="TAKE_AWAY_LIST_SETTINGS" component={TakeAwayListSettings} />

      {/* END TAKE AWAY SETTINGS */}

      {/* START DELIVERY SETTINGS */}
      <Stack.Screen
        name="DELIVERY_GENERAL_SETTINGS"
        component={DeliveryGeneralSettings}
      />
      <Stack.Screen name="DELIVERY_HOURS_SETTINGS" component={DeliveryHoursSettings} />
      <Stack.Screen
        name="DELIVERY_CLOSURES_SETTINGS"
        component={DeliveryClosuresSettings}
      />
      <Stack.Screen name="DELIVERY_FORM_SETTINGS" component={DeliveryFormSettings} />
      <Stack.Screen name="DELIVERY_LIST_SETTINGS" component={DeliveryListSettings} />
      {/* END DELIVERY SETTINGS */}

      <Stack.Screen
        name="QUOTES_INVOICE_SETTINGS_LIST"
        component={QuoteInvoiceSettings}
      />
      <Stack.Screen name="QUOTE_SETTINGS" component={QuoteSettings} />
      <Stack.Screen name="INVOICE_SETTINGS" component={InvoiceSettings} />

      <Stack.Screen name="ACCOUNTING_SETTINGS_LIST" component={AccountSettingsList} />

      <Stack.Screen
        name="PAYMENT_METHODS"
        component={CashRegisterSettingsPaymentMethods}
      />

      <Stack.Screen name="ACCOUNTING_REPORT_SETTINGS" component={AccountingReports} />
      <Stack.Screen name="TAX_LIST" component={TaxList} />
      <Stack.Screen name="TAX_LIST_DETAILS" component={TaxListDetails} />

      <Stack.Screen name="DISCOUNTS_LIST" component={MerchantDiscountList} />
      <Stack.Screen
        name="DISCOUNTS_LIST_DETAILS"
        component={MerchantDiscountListDetails}
      />

      <Stack.Screen name="PRICING_RATES_LIST" component={PricingRatesList} />
      <Stack.Screen
        name="PRICING_RATES_LIST_DETAILS"
        component={PricingRatesListDetails}
      />

      <Stack.Screen name="DATA_SETTINGS_LIST" component={DataSettingsList} />

      <Stack.Screen name="OPTIONS_LIST" component={OptionsList} />
      <Stack.Screen name="OPTIONS_LIST_DETAILS" component={OptionsListDetails} />

      <Stack.Screen name="GROUP_OPTIONS_LIST" component={GroupOptionsList} />
      <Stack.Screen
        name="GROUP_OPTIONS_LIST_DETAILS"
        component={GroupOptionsListDetails}
      />
      <Stack.Screen
        name="GROUP_OPTIONS_LIST_DETAILS_ADD_OPTION"
        component={GroupOptionAddOption}
      />

      <Stack.Screen
        name="PRODUCTS_WITH_STOCK_ENABLED_LIST"
        component={ProductsWithStockEnabledList}
      />

      <Stack.Screen name="STOCK_LIST" component={StockList} />

      <Stack.Screen name="PRODUCTS_LIST" component={ProductList} />
      <Stack.Screen name="PRODUCTS_LIST_DETAILS" component={ProductListDetails} />
      <Stack.Screen
        name="PRODUCTS_LIST_DETAILS_ADD_CATEGORY_OR_GROUP_OPTION"
        component={ProductAddCategoryGroupOption}
      />

      <Stack.Screen name="CATEGORIES_LIST" component={CategoryList} />
      <Stack.Screen name="CATEGORIES_LIST_DETAILS" component={CategoryListDetails} />
      <Stack.Screen
        name="CATEGORIES_LIST_DETAILS_ADD_PRODUCT"
        component={CategoryAddProduct}
      />

      <Stack.Screen name="COMBINED_CATEGORIES_LIST" component={CombinedCategoryList} />
      <Stack.Screen
        name="COMBINED_CATEGORIES_LIST_DETAILS"
        component={CombinedCategoryListDetails}
      />
      <Stack.Screen
        name="COMBINED_CATEGORIES_LIST_DETAILS_ADD_CATEGORY"
        component={CombinedCategoryAddCategory}
      />
      <Stack.Screen name="MENUS_LIST" component={MenuList} />
      <Stack.Screen name="MENUS_LIST_DETAILS" component={MenuListDetails} />
      <Stack.Screen name="MENUS_LIST_DETAILS_STEP" component={MenuStep} />
      <Stack.Screen
        name="MENUS_LIST_DETAILS_STEP_ADD_PRODUCT"
        component={MenuStepAddProduct}
      />
      {/* START ACCOUNT & SUBSCRIPTION */}

      <Stack.Screen
        name="ACCOUNT_AND_SUBSCRIPTION_SETTINGS_LIST"
        component={AccountAndSubscriptions}
      />

      <Stack.Screen name="ACCOUNT_DETAILS" component={AccountDetails} />
      <Stack.Screen name="ACCOUNT_SECURITY" component={AccountSecurity} />
      <Stack.Screen name="ACCOUNT_BILLING" component={AccountBillingDetails} />
      <Stack.Screen name="ACCOUNT_BILLING_DETAILS" component={BillingDetails} />
      <Stack.Screen
        name="ACCOUNT_BILLING_ADD_PAYMENT_METHOD"
        component={AddPaymentMethod}
      />
      <Stack.Screen
        name="ACCOUNT_BILLING_PAYMENT_METHOD_DETAILS"
        component={PaymentMethodDetails}
      />

      <Stack.Screen name="SUBSCRIPTION_DETAILS" component={SubscriptionDetails} />
      <Stack.Screen name="ADD_SUBSCRIPTION" component={AddSubscription} />
      <Stack.Screen name="INVOICE_LIST" component={InvoicesList} />
      {/* END ACCOUNT & SUBSCRIPTION */}

      <Stack.Screen name="HELP" component={Help} />
      <Stack.Screen name="DIGITAL_MENUS_LIST" component={DigitalMenuList} />
      <Stack.Screen
        name="DIGITAL_MENUS_LIST_DETAILS"
        component={DigitalMenuListDetails}
      />

      <Stack.Screen
        name="MERCHANT_ORGANISATION"
        component={MerchantOrganisationMerchants}
      />

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="ADDRESS_DETAILS" component={AddressDetails} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default SettingsStack;
