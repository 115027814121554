import { useContext, useEffect, useState } from "react";

import MAIL from "../../../assets/icons/BASE/MAIL.svg";
import { AppContext } from "../../contexts/AppContext";
import { SwitchStaffAccountContext } from "../../contexts/SwitchStaffAccount";
import type { MerchantPersonnelFragment } from "../../graphql/generated/schema";
import {
  useGetMerchantPersonnelsLazyQuery,
  useLoginMerchantPersonnelMutation,
} from "../../graphql/generated/schema";
import { ICON_SIZE } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import Box from "../Base/Box";
import BottomButton from "../BottomButton";
import ContentModal from "../ContentModal";
import SelectButton from "../Select/SelectButton";
import { CustomTextInput } from "../TextInput";

const SwitchStaffAccount = () => {
  const { isStaffAccountModalOpen, setIsStaffAccountModalOpen } = useContext(
    SwitchStaffAccountContext,
  );
  const appContext = useContext(AppContext);

  const [staff, setStaff] = useState<MerchantPersonnelFragment[]>([]);
  const [staffId, setStaffId] = useState("");
  const [staffPassword, setStaffPassword] = useState("");

  const [getMerchantPersonnels] = useGetMerchantPersonnelsLazyQuery();
  const [loginByStaffIdAndPassword] = useLoginMerchantPersonnelMutation();

  const handleGetMerchantPersonnels = async () => {
    try {
      if (appContext.isOwner) return;

      const { data } = await getMerchantPersonnels({
        variables: {
          pagination: {
            offset: 0,
            limit: 30,
          },
        },
      });
      if (data?.getMerchantPersonnels) {
        setStaff(data?.getMerchantPersonnels);
      }
    } catch (err) {
      console.log("err getMerchantPersonnels", err);
    }
  };

  useEffect(() => {
    handleGetMerchantPersonnels();
  }, []);

  const merchantPersonnelOptions = staff
    .filter(s => s._id !== appContext.staffId)
    .map(merchantPersonnel => ({
      label: `${merchantPersonnel.firstName || ""} ${merchantPersonnel.lastName || ""}`,
      key: merchantPersonnel._id,
    }));

  const handleClose = () => {
    setStaffId("");
    setStaffPassword("");
    setIsStaffAccountModalOpen(false);
  };
  const handleSwitchAccount = async () => {
    // !FIXME alert error if no staff id or password
    if (!staffId || !staffPassword) return;

    const { licenceKey } = appContext;

    try {
      const { data } = await loginByStaffIdAndPassword({
        variables: {
          licenceKey,
          staffId,
          password: staffPassword,
        },
      });

      if (data?.loginMerchantPersonnel) {
        const apiToken = data.loginMerchantPersonnel;
        appContext?.loginUser(apiToken, licenceKey, staffId);

        handleClose();
      } else {
        console.log("Erro login");
      }
    } catch (err) {
      // !FIXME alert invalid credentials
      console.log("err staff login", JSON.stringify(err, null, 2));
    }
  };

  return (
    <ContentModal
      isVisible={isStaffAccountModalOpen}
      title="Changer de compte"
      onClose={handleClose}
    >
      <>
        <Box height="80%" backgroundColor="white">
          <Box mt="s">
            <SelectButton
              isMultiSelect={false}
              options={merchantPersonnelOptions}
              selectedOptions={staffId ? [staffId] : []}
              onPress={([id]) => setStaffId(id)}
              placeHolder="Utilisateur"
            />

            <CustomTextInput
              {...{
                onChangeText: setStaffPassword,
                placeHolder: "Mot de passe",
                leftIcon: (
                  <MAIL height={ICON_SIZE} width={ICON_SIZE} fill={PALETTE.green} />
                ),
                secureTextEntry: true,
                boxProps: {
                  mt: "s",
                },
              }}
            />
          </Box>
        </Box>
        <BottomButton title="Valider" onPress={handleSwitchAccount} />
      </>
    </ContentModal>
  );
};

export default SwitchStaffAccount;
