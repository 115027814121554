import { useEffect, useState } from "react";

import { Pressable } from "react-native";
import type { BookingFragment, PrepaidPayment } from "../../../graphql/generated/schema";
import { Booking_Status } from "../../../graphql/generated/schema";
import Box from "../../Base/Box";
import BookingStatusListModal from "../../StatusButton/BookingStatusButton/BookingStatusListModal";
import BookingListCardStatusButtonItem, {
  BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE,
} from "../BookingListCardStatusButtonItem";
import Touchable from "../../Touchable";

interface BookingListCardStatusButtonProps {
  status: Booking_Status;
  type?: BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE;
  isDisabled?: boolean;
  onPress: (
    status: Booking_Status,
    refusalReason: string,
    applyAutomaticPrepaymentPercentage: boolean,
    reimburse: boolean,
  ) => void;
  prepaidPayment?: PrepaidPayment | null;
  payment?: Omit<
    BookingFragment["payment"],
    "paymentIntentId" | "prepaymentCapturePercentage"
  > | null;
  setIsOpen?: (v: boolean) => void;
}

const BookingListCardStatusButton = ({
  type = BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.ICON,
  isDisabled = false,
  onPress,
  status,
  prepaidPayment,
  payment,
  setIsOpen,
}: BookingListCardStatusButtonProps) => {
  const [newStatusValue, setNewStatusValue] = useState(status);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  useEffect(() => {
    setNewStatusValue(status);
  }, [status]);

  const handlePress = () => {
    setIsStatusModalOpen(true);
    setIsOpen?.(true);
  };

  const handleStatusChange = (
    newStatus: Booking_Status,
    refualReason: string,
    automaticPercentage: boolean,
    shouldReimburse: boolean,
  ) => {
    setIsStatusModalOpen(false);
    onPress(newStatus, refualReason, automaticPercentage, shouldReimburse);
  };

  const handleClose = () => {
    setIsStatusModalOpen(false);
    setIsOpen?.(false);
  };

  const HAS_PAID = prepaidPayment?.paid || payment?.paid;

  const hasBookingStatusChangeReachedEnd =
    (newStatusValue === Booking_Status.Ended && !HAS_PAID) ||
    newStatusValue === Booking_Status.Noshow ||
    newStatusValue === Booking_Status.CancelledByOwner ||
    newStatusValue === Booking_Status.CancelledByUser ||
    newStatusValue === Booking_Status.Refunded;

  const isStatusButtonDisabled = isDisabled || hasBookingStatusChangeReachedEnd;

  return (
    <Box>
      <Touchable disabled={isStatusButtonDisabled} onPress={handlePress}>
        <BookingListCardStatusButtonItem status={status} type={type} />
      </Touchable>
      <BookingStatusListModal
        showAllStatuses={type === BOOKING_LIST_CARD_STATUS_LAYOUT_TYPE.TEXT}
        isVisible={isStatusModalOpen}
        status={status}
        onClose={handleClose}
        onPress={handleStatusChange}
        prepaidPayment={prepaidPayment}
        payment={payment}
      />
    </Box>
  );
};

export default BookingListCardStatusButton;
