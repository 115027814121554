/* eslint-disable @typescript-eslint/no-empty-function */

import { useNavigation } from "@react-navigation/native";
import { useContext, useEffect, useState } from "react";
import { FlatList, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import BOOKING_FLOOR from "../../../../assets/icons/BASE/BOOKING_FLOOR.svg";
import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import SELECTED_TABLE from "../../../../assets/icons/BASE/SELECTED_TABLE.svg";
import { AppContext } from "../../../contexts/AppContext";
import { BookingContext } from "../../../contexts/BookingContext";
import type {
  BookingFragment,
  SelectedBookingTableFragment,
} from "../../../graphql/generated/schema";
import {
  Module_Listing_Client_Format,
  useGetBookingLazyQuery,
  useGetBookingsLinkedToTablesLazyQuery,
} from "../../../graphql/generated/schema";
import { LINE_THICKNESS } from "../../../theme";
import { PALETTE } from "../../../theme/Palette";
import { BOOKING_FLOOR_VIEW_TYPE } from "../../../types";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import { CustomButton } from "../../Button";
import ContentModal from "../../ContentModal";
import Touchable from "../../Touchable";
import BookingListCard from "../BookingListCard";

interface BookingCardAssignTablesModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  bookingId: string;
  onSubmit: (tables: SelectedBookingTableFragment[]) => void;
  selectedServiceId?: string;
}

const IS_WEB = Platform.OS === "web";

const BookingCardAssignTablesModal = ({
  isOpen,
  setIsOpen,
  bookingId,
  onSubmit,
  selectedServiceId,
}: BookingCardAssignTablesModalProps) => {
  const navigation = useNavigation();
  const bookingContext = useContext(BookingContext);
  const appContext = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [booking, setBooking] = useState<BookingFragment | null>(null);
  const [linkedBookings, setLinkedBookings] = useState<BookingFragment[]>([]);

  const [getBooking] = useGetBookingLazyQuery();
  const [getBookingsLinkedToTables] = useGetBookingsLinkedToTablesLazyQuery();

  const IS_BTN_ABSOLUTE = !IS_WEB || !appContext.isSplittable;
  const handleGetLinkedBookingsToTables = async () => {
    try {
      const { data } = await getBookingsLinkedToTables({
        variables: {
          bookingId,
        },
      });

      setLinkedBookings(data?.getBookingsLinkedToTables || []);
    } catch (err) {
      console.log("err get booking", err);
    }
  };

  useEffect(() => {
    handleGetLinkedBookingsToTables();
  }, []);

  const handleGetBooking = async () => {
    try {
      if (bookingId) {
        const { data } = await getBooking({
          variables: {
            bookingId,
          },
        });

        if (data?.getBookingForPro) {
          setBooking(data?.getBookingForPro);
        }

        // bookingContext.setTablesForBookingAssignment(data?.getBookingForPro?.tables || []);

        // setSelectedTables(data?.getBookingForPro?.tables || []);
      }
    } catch (err) {
      console.log("err get booking", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetBooking();
  }, []);

  const handleOnSubmit = () => {
    // const consolidatedSelectedTables = tables
    //   .filter(table => tableIds.includes(table._id))
    //   .map(table => ({
    //     tableId: table.tableId,
    //     floorId: table.floorId,
    //     prefix: table.prefix,
    //     tableNumber: table.tableNumber,
    //   }));

    onSubmit(bookingContext.tablesForBookingAssignment);

    handleClose();
  };

  const handleGoToBookingFloor = () => {
    setIsOpen(false);
    navigation.navigate("BOOKING_FLOORS", {
      selectedTables: bookingContext.tablesForBookingAssignment,
      selectedDate: booking?.date,
      bookingPersons: booking?.nbPersons,
      bookingFloorViewType: BOOKING_FLOOR_VIEW_TYPE.TABLE_ASSIGNMENT,
      selectedBookingServiceId: selectedServiceId,
      onSubmit: function (consolidatedSelectedTables: SelectedBookingTableFragment[]) {
        // consolidatedSelectedTables.forEach(table => handleTableSelection(table));

        bookingContext.setTablesForBookingAssignment(consolidatedSelectedTables);

        // setSelectedTables(consolidatedSelectedTables);

        // console.log("consolidatedSelectedTables", consolidatedSelectedTables);

        // const selectedTableIds = consolidatedSelectedTables.map(table => table.tableId);

        // console.log("selectedTableIds", selectedTableIds);

        // setTableIds(prev => {
        //   console.log("ok", [...prev, ...selectedTableIds]);
        //   return [...prev, ...selectedTableIds];

        //   // return uniqueInArr([...prev, ...selectedTableIds]) as string[];
        // });

        navigation.goBack();
        setIsOpen(true);
      },
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    bookingContext.setTablesForBookingAssignment([]);
    // setTableIds([]);
  };

  const handleTableSelection = (table: SelectedBookingTableFragment) => {
    if (
      bookingContext.tablesForBookingAssignment.some(t => t.tableId === table.tableId)
    ) {
      bookingContext.setTablesForBookingAssignment(prev =>
        prev.filter(t => t.tableId !== table.tableId),
      );
    } else {
      bookingContext.setTablesForBookingAssignment(prev => [...prev, table]);
    }
  };

  const displaySelectedTable = (table: SelectedBookingTableFragment) => {
    const { prefix, tableNumber } = table;

    return (
      <Touchable onPress={() => handleTableSelection(table)}>
        <Box mr="s">
          <SELECTED_TABLE />

          <Box
            position="absolute"
            top="35%"
            left="20%"
            alignItems="center"
            justifyContent="center"
          >
            <CustomText variant="content" fontSize={12} color="primaryTextColor">
              {prefix}-{tableNumber}
            </CustomText>
          </Box>
        </Box>
      </Touchable>
    );
  };

  if (!isOpen) return null;

  if (!booking || loading) return null;

  const selectedTablesPersons = bookingContext.tablesForBookingAssignment.reduce(
    (acc, table) => acc + table.maxCapacity,
    0,
  );
  // const selectedTablesPersons = tables
  //   .filter(table => tableIds.includes(table._id))
  //   .reduce((acc, table) => acc + table.minCapacity, 0);

  const BUTTON_COLOR = selectedTablesPersons >= booking.nbPersons ? "success" : "orange";
  const ICON_COLOR = selectedTablesPersons >= booking.nbPersons ? "green" : "orange";

  const remainingTableSpots = () => {
    // const selectedTables = tables.filter(table => tableIds.includes(table._id));

    const maxCapacity = bookingContext.tablesForBookingAssignment.reduce(
      (acc, table) => acc + table.maxCapacity,
      0,
    );

    return maxCapacity - booking.nbPersons;
  };

  return (
    <ContentModal
      title="Assignation de tables"
      isVisible={isOpen}
      onClose={handleClose}
      hasBackButton={false}
      styles={{
        paddingHorizontal: 0,
        maxWidth: 450,
        position: appContext.isSplittable ? "absolute" : "relative",
        left: appContext.isSplittable ? 30 : 0,
      }}
      headerStyles={{
        paddingHorizontal: 8,
      }}
    >
      <Box height="100%" maxWidth={450}>
        <Box height="100%">
          <Box
            minHeight={100}
            paddingHorizontal="s"
            pt="s"
            // paddingVertical="m"
            // mb="s"
            // paddingVertical="l"
            borderTopColor="lightGrey"
            borderTopWidth={1}
            borderBottomColor="lightGrey"
            borderBottomWidth={1}
            justifyContent="center"
            // backgroundColor="danger"
          >
            <BookingListCard
              item={booking}
              nameFormat={Module_Listing_Client_Format.LastFirstName}
              isDisabled
              onPress={() => {}}
              onTablePress={() => {}}
              onExtraActionPress={() => {}}
              onDelete={() => {}}
              isExtraActionDisabled
              isTablePressDisabled
              isStatusChangeDisabled
              showClientComment={false}
              showCreationDate={false}
              showEventType={false}
            />
          </Box>

          <Box
            mt="s"
            paddingHorizontal="s"
            paddingVertical="s"
            // borderBottomColor="lightGrey"
            // borderBottomWidth={1}
            justifyContent="center"
          >
            <CustomText variant="label" color="primaryTextColor">
              Tables assignées
            </CustomText>

            <Box
              mt="s"
              justifyContent="flex-start"
              minHeight={{
                phone: 100,
                tablet: 300,
              }}
            >
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                {bookingContext.tablesForBookingAssignment.map(table => {
                  return (
                    <Box key={table.tableId} mr="s">
                      {displaySelectedTable(table)}
                    </Box>
                  );
                })}
              </ScrollView>

              {/* <FlatList
                horizontal
                showsHorizontalScrollIndicator={false}
                data={selectedTables}
                keyExtractor={item => item.tableId}
                renderItem={({ item }) => displaySelectedTable(item)}
              /> */}
            </Box>

            {remainingTableSpots() > 0 && (
              <Box
                mt="xs"
                minHeight={45}
                backgroundColor="greenBackground"
                borderRadius="button"
                borderWidth={LINE_THICKNESS}
                borderColor="success"
                alignItems="center"
                justifyContent="center"
              >
                <CustomText variant="content" color="success">
                  {remainingTableSpots()} place(s) sont disponibles sur cette table.
                </CustomText>
              </Box>
            )}
          </Box>

          {linkedBookings?.length > 0 && (
            <Box flex={1}>
              <Box backgroundColor="disabled" p="s">
                <CustomText variant="label" fontSize={12} color="primaryTextColor">
                  Réservations assignées à cette table
                </CustomText>
              </Box>
              <Box paddingHorizontal="s">
                <FlatList
                  style={{
                    height: IS_WEB ? "150px" : "100%",
                    overflow: "scroll",
                  }}
                  contentContainerStyle={{ paddingBottom: 150 }}
                  showsVerticalScrollIndicator={false}
                  data={linkedBookings}
                  keyExtractor={item => item._id}
                  renderItem={({ item, index }) => {
                    const IS_LAST = index === linkedBookings.length - 1;
                    return (
                      <Box
                        marginVertical="s"
                        borderBottomColor="lightGrey"
                        borderBottomWidth={IS_LAST ? 0 : 1}
                      >
                        <BookingListCard
                          item={item}
                          nameFormat={Module_Listing_Client_Format.LastFirstName}
                          isDisabled
                          onPress={() => {}}
                          onTablePress={() => {}}
                          onExtraActionPress={() => {}}
                          onDelete={() => {}}
                        />
                      </Box>
                    );
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box
          position={IS_BTN_ABSOLUTE ? "absolute" : "relative"}
          bottom={IS_BTN_ABSOLUTE ? 20 : 0}
          left={0}
          right={0}
          p="s"
          pb={!IS_BTN_ABSOLUTE ? "xl" : "m"}
          // pb={{
          //   phone: "xl",
          //   tablet: "m",
          // }}
          backgroundColor="white"
          borderTopColor="lightGrey"
          borderTopWidth={1}
        >
          <CustomButton
            buttonVariant="outlineButton"
            borderColor={BUTTON_COLOR}
            onPress={handleGoToBookingFloor}
            overrideStyles={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box />
            <Box flex={1}>
              <CustomText variant="primaryButtonText" color={BUTTON_COLOR}>
                {selectedTablesPersons} / {booking.nbPersons} pax
              </CustomText>
            </Box>
            <Box
              flex={0.2}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mr="s"
            >
              {/* <Box height="100%" width={5} backgroundColor="danger" /> */}

              <BOOKING_FLOOR fill={PALETTE[ICON_COLOR]} />

              <RIGHT_ARROW width={12} height={12} fill={PALETTE[ICON_COLOR]} />
            </Box>
          </CustomButton>

          <Box marginVertical="m" borderRadius="button">
            <CustomButton
              buttonVariant="primaryButton"
              buttonColor="success"
              onPress={handleOnSubmit}
            >
              <CustomText variant="primaryButtonText" color="white">
                Valider
              </CustomText>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </ContentModal>
  );
};

export default BookingCardAssignTablesModal;
