import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Image } from "react-native";
import HOME from "../../../../assets/icons/BASE/HOME.svg";
import RIGHT_ARROW from "../../../../assets/icons/BASE/RIGHT_ARROW.svg";
import {
  GetMerchantOrganisationQuery,
  MerchantFragment,
  useGetMerchantLazyQuery,
  useGetMerchantOrganisationLazyQuery,
} from "../../../graphql/generated/schema";
import { PALETTE } from "../../../theme/Palette";
import Box from "../../Base/Box";
import { CustomText } from "../../Base/Text";
import SettingsButton from "../../Settings/SettingsButton";
import Touchable from "../../Touchable";

interface MerchantOrganisationSettingsCardProps {}

const MerchantOrganisationSettingsCard = (
  props: MerchantOrganisationSettingsCardProps,
) => {
  const navigation = useNavigation();

  const [merchant, setMerchant] = useState<MerchantFragment | null>(null);
  const [merchantOrganisation, setMerchantOrganisation] = useState<
    GetMerchantOrganisationQuery["getMerchantOrganisation"] | null
  >(null);

  const [getMerchantOrganisation] = useGetMerchantOrganisationLazyQuery();
  const [getMerchant] = useGetMerchantLazyQuery();

  const handleGetMerchantOrganisation = async () => {
    try {
      const response = await getMerchantOrganisation();

      setMerchantOrganisation(response.data?.getMerchantOrganisation || null);
    } catch (err) {
      console.log("err get merchant organisation: ", err);
    }
  };

  const handleGetMerchant = async () => {
    try {
      const response = await getMerchant();

      setMerchant(response.data?.getMerchant || null);
    } catch (err) {
      console.log("err get merchant: ", err);
    }
  };

  const handleInit = async () => {
    try {
      await handleGetMerchantOrganisation();
      await handleGetMerchant();
    } catch (err) {
      console.log("err init: ", err);
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  const doesMerchantOrganisationHaveMultiMerchants =
    !!merchantOrganisation && merchantOrganisation?.merchants?.length > 0;

  const handleGoToMerchantInfoSettings = () =>
    navigation.navigate("ESTABLISHMENT_SETTINGS_LIST");

  const handleGoToMerchantOrganisation = () =>
    navigation.navigate("MERCHANT_ORGANISATION");

  return (
    <Box backgroundColor="disabled" borderRadius="button" p="m">
      <Touchable onPress={handleGoToMerchantInfoSettings}>
        <Box flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box flexDirection="row" alignItems="center">
            {merchant?.coverImage && (
              <Image
                source={{ uri: merchant?.coverImage }}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 20,
                  marginRight: 10,
                }}
              />
            )}

            <Box>
              <CustomText variant="label" color="primaryTextColor">
                {merchant?.label}
              </CustomText>
              <CustomText variant="paragraph" color="lightGrey">
                Informations établissement
              </CustomText>
            </Box>
          </Box>
          <RIGHT_ARROW height={18} width={18} fill={PALETTE.green} />
        </Box>
      </Touchable>

      {doesMerchantOrganisationHaveMultiMerchants && (
        // <Touchable onPress={handleGoToMerchantOrganisation}>
        <Box mt="s" borderRadius="button" borderWidth={1} borderColor="disabled">
          <SettingsButton
            {...{
              color: "primaryTextColor",
              leftIcon: <HOME height={18} width={18} fill={PALETTE.green} />,
              text: "Autres établissements",
              onPress: handleGoToMerchantOrganisation,
              shouldDisplayRightIcon: true,
            }}
          />
        </Box>
        // {/* </Touchable> */}
      )}
    </Box>
  );
};

export default MerchantOrganisationSettingsCard;
